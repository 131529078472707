import React, {useMemo} from 'react';
import {Select, Input, Col, Row, Button} from 'antd';
import styled, {css} from 'styled-components';
import DateTimePicker from '../../../Components/DateTimePicker';
import {LOGISTIC_TRACKING_URL, DELIVERY_TYPE} from '../../../dictionary';
import {getStatusSet} from '../../../Utils/LogisticUtil';
import RowText from '../../../Widgets/RowText';
const appConfig = require('../../../data.json');

const StyledRowText = (props) => {
  return (
    <RowText
      size="md"
      color="#6B7280"
      containerStyle={{marginBottom: '12px'}}
      labelStyle={{flex: '0 0 100px'}}
      textStyle={{marginLeft: '16px', color: '#000', fontWeight: '500'}}
      noColon
      {...props}
    />
  );
};

const BasicForm = ({logistic, updateLogistic, editableFields = {}}) => {
  let statusSet = useMemo(
    () => getStatusSet({type: logistic?.logistics_type}),
    [logistic?.logistics_type],
  );
  const {allFields = true, status = true} = editableFields;
  if (!allFields) {
    return (
      <div>
        <div style={{marginBottom: '16px'}}>
          <StyledLabel>物流狀態</StyledLabel>
          <StyledSelect
            value={logistic?.logistics_status}
            onChange={(value) => updateLogistic({logistics_status: value})}>
            {Object.keys(statusSet).map((key) => (
              <Select.Option
                value={key}
                key={key}
                disabled={
                  ['default', 'packaging', 'tallying', 'pending'].includes(
                    logistic?.logistics_status,
                  )
                    ? ![
                        'default',
                        'packaging',
                        'tallying',
                        'pending',
                        'transit',
                      ].includes(key)
                    : false
                }>
                {statusSet[key]}
              </Select.Option>
            ))}
          </StyledSelect>
        </div>
        <StyledRowText
          label="運送方式"
          value={DELIVERY_TYPE[logistic?.logistics_type]}
        />
        <StyledRowText label="追蹤單號" value={logistic?.tracking_number} />
        <StyledRowText label="預計出貨時間" value={logistic?.shipping_time} />
        <StyledRowText label="預計收穫時間" value={logistic?.receipt_time} />
      </div>
    );
  }
  return (
    <div>
      <Row gutter={[48, 24]}>
        <Col span={24}>
          <StyledLabel>物流狀態</StyledLabel>
          <StyledSelect
            value={logistic?.logistics_status}
            onChange={(value) => updateLogistic({logistics_status: value})}>
            {Object.keys(statusSet).map((key) => (
              <Select.Option
                value={key}
                key={key}
                disabled={
                  ['default', 'packaging', 'tallying', 'pending'].includes(
                    logistic?.logistics_status,
                  )
                    ? ![
                        'default',
                        'packaging',
                        'tallying',
                        'pending',
                        'transit',
                      ].includes(key)
                    : false
                }>
                {statusSet[key]}
              </Select.Option>
            ))}
          </StyledSelect>
        </Col>
        <Col span={12}>
          <InputGroup>
            <StyledLabel required>運送方式</StyledLabel>
            <StyledSelect
              value={logistic?.logistics_type}
              onChange={(value) => updateLogistic({logistics_type: value})}>
              {Object.keys(DELIVERY_TYPE)
                .filter((key) => appConfig.supportLogistics.includes(key))
                // ezship 物流單不能在後台產生
                .filter((key) => (key === 'ezship' ? false : true))
                .map((key) => (
                  <Select.Option
                    value={key}
                    key={key}
                    disabled={[
                      'ezship',
                      'tcat',
                      'kerry_tj',
                      'maple',
                      'payuni',
                    ].includes(key)}>
                    {DELIVERY_TYPE[key]}
                  </Select.Option>
                ))}
            </StyledSelect>
          </InputGroup>
        </Col>
        <Col span={12}>
          <StyledLabel>追蹤單號</StyledLabel>
          <TrackingContainer>
            <StyledInput
              type="text"
              value={logistic?.tracking_number}
              onChange={(e) =>
                updateLogistic({tracking_number: e.target.value})
              }
            />
            {LOGISTIC_TRACKING_URL[logistic?.logistics_type] ? (
              <StyledButton
                href={LOGISTIC_TRACKING_URL[logistic?.logistics_type]}
                target="_blank"
                rel="noreferrer">
                查件連結
              </StyledButton>
            ) : null}
          </TrackingContainer>
        </Col>
        <Col span={12}>
          <InputGroup>
            <StyledLabel>預計出貨時間</StyledLabel>
            <StyledDateTimePicker
              allowNull={true}
              dateOnly={true}
              datetime={logistic?.shipping_time}
              onChange={(value) => updateLogistic({shipping_time: value})}
              placeholder="計算後填入時間"
            />
          </InputGroup>
        </Col>
        <Col span={12}>
          <InputGroup>
            <StyledLabel>指定收貨時間</StyledLabel>
            <StyledDateTimePicker
              allowNull={true}
              datetime={logistic?.receipt_time}
              onChange={(value) => updateLogistic({receipt_time: value})}
              placeholder="計算後填入時間"
            />
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};

const baseInputStyle = css`
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
`;

const TrackingContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  input {
    flex: 1;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  .ant-space,
  .ant-space-item {
    width: 100%;
  }
`;

const StyledInput = styled(Input)`
  ${baseInputStyle}
  width: 100%;
  height: 48px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  border: none;
  &&& {
    .ant-select-selector {
      padding: 12px 16px;
      height: 48px;
    }
    .ant-select-selection-item {
      line-height: 24px;
    }
    .ant-select-arrow {
      right: 16px;
    }
  }
`;

const StyledDateTimePicker = styled(DateTimePicker)`
  &&& {
    ${baseInputStyle}
    width: 100%;
    height: 48px;
  }
`;

const StyledLabel = styled.h4`
  font-size: 14px;
  font-weight: 400;
  color: #9b9b9b;
  margin-bottom: 8px;
  ${(props) =>
    props.required &&
    css`
      &::before {
        content: '*';
        color: #9b9b9b;
        margin-right: 4px;
      }
    `}
`;

const StyledButton = styled(Button)`
  &&& {
    padding: 12px 16px !important;
    height: 48px;
    border-radius: 12px;
    border: 1px solid #227fe9;
    color: #227fe9;
    line-height: 22px;
  }
`;

export default BasicForm;
