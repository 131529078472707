import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {errorHandler} from '../errors';
import {Spin, Input, Select} from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';

// 將 StyledSelect 移到組件外部
const StyledSelect = styled(Select)`
  width: calc(33.33333% - 16px / 3);
  flex: 0 0 calc(33.33333% - 16px / 3);
  border: none;
  &&& {
    .ant-select-selector {
      padding: 12px 16px;
      height: 48px;
    }
    .ant-select-selection-item {
      line-height: 24px;
    }
    .ant-select-arrow {
      right: 16px;
    }
  }
`;

export default function AddressField(props) {
  const {
    onChange,
    zip_code = null,
    city = null,
    district = null,
    address = '',
    fullWidth = false,
  } = props;

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [actions] = useOutlet('actions');

  const getZipCode = useCallback(async (zip_code) => {
    setLoading(true);
    try {
      let resp = await actions.getZipCode(zip_code);
      if (resp) {
        onChange({
          zip_code,
          city: resp.countyName,
          district: resp.townName,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let resp = await actions.getCities();
        setCities(resp);
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    })();
  }, []);

  const getDistricts = useCallback(async () => {
    if (city) {
      setLoading(true);
      try {
        let resp = await actions.getDistricts(city);
        setDistricts(resp);
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    }
  }, [props.city]);

  useEffect(() => {
    getDistricts();
  }, [getDistricts]);

  const fieldStyle = fullWidth
    ? {
        flex: '0 0 calc(33.33333% - 16px / 3)',
        width: 'calc(33.33333% - 16px / 3)',
        height: '48px',
        padding: '12px 16px',
        borderRadius: '6px',
        border: '1px solid #d9d9d9',
      }
    : {};

  const SelectComponent = useMemo(() => (fullWidth ? StyledSelect : Select), [
    fullWidth,
  ]);

  return (
    <AddressFieldContainer fullWidth={fullWidth}>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{fontSize: 16}} />}
        style={{alignSelf: 'center', marginRight: 10, marginBottom: 10}}
      />
      <Input
        placeholder="郵遞區號"
        disabled={loading}
        value={zip_code}
        onChange={(e) => {
          let value = e.target.value;
          onChange({zip_code: value});
        }}
        onBlur={() => {
          if (/[0-9]{3}/g.test(zip_code)) {
            getZipCode(zip_code);
          }
        }}
        style={{
          ...(fullWidth
            ? {...fieldStyle}
            : {width: 80, flexBasis: 80, marginRight: 10, marginBottom: 10}),
        }}
      />

      <SelectComponent
        placeholder="縣市"
        value={city || ''}
        onChange={(value) => {
          onChange({
            city: value,
            district: null,
            zip_code: null,
          });
        }}
        disabled={loading}
        style={{
          ...(fullWidth
            ? {}
            : {width: 100, flexBasis: 100, marginRight: 10, marginBottom: 10}),
        }}>
        <Select.Option value="" key="empty" disabled>
          縣市
        </Select.Option>
        {cities.map((c) => (
          <Select.Option key={c.countyName} value={c.countyName}>
            {c.countyName}
          </Select.Option>
        ))}
      </SelectComponent>

      <SelectComponent
        placeholder="鄉鎮市區"
        value={district || ''}
        onChange={(value) => {
          onChange({district: value});

          // set zip_code
          let instance = districts.find((t) => t.townName === value);

          if (instance) {
            onChange({
              district: value,
              zip_code: instance.zipCode,
            });
          }
        }}
        disabled={loading}
        style={{
          ...(fullWidth ? {} : {width: 100, flexBasis: 100, marginBottom: 10}),
        }}>
        <Select.Option value="" key="empty" disabled>
          鄉鎮市區
        </Select.Option>
        {districts.map((t) => (
          <Select.Option key={t.townName} value={t.townName}>
            {t.townName}
          </Select.Option>
        ))}
      </SelectComponent>

      <Input
        placeholder="詳細地址路段"
        disabled={loading}
        value={address}
        onChange={(e) => onChange({address: e.target.value})}
        style={{
          ...(fullWidth
            ? {...fieldStyle, flex: 'auto'}
            : {width: '100%', flexBasis: 'auto'}),
        }}
      />
    </AddressFieldContainer>
  );
}

const AddressFieldContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: ${(props) => (props.fullWidth ? '100%' : '300px')};
  gap: ${(props) => (props.fullWidth ? '8px' : '0px')};
`;
