import React from 'react';
import Row from './Row';
import Text from './Text';

export default function RowText({
  label,
  size = 'sm',
  weight = '500',
  value,
  labelStyle,
  textStyle,
  children,
  noColon = false,
  containerStyle,
  ...props
}) {
  return (
    <Row style={{...containerStyle}}>
      <Text
        size={size}
        weight={weight}
        color="#505050"
        style={{...labelStyle}}
        {...props}>
        {label}
        {!noColon && '：'}
      </Text>
      <Text
        size={size}
        weight={weight}
        color="#505050"
        style={{marginRight: '4px', ...textStyle}}
        {...props}>
        {value}
      </Text>
      {children}
    </Row>
  );
}
