import React, {useEffect, useCallback, useMemo} from 'react';
import BasicForm from '../Forms/Basic';
import ShippingInfoForm from '../Forms/Shipping';
import styled from 'styled-components';
import {Input} from 'antd';
import {getEditableFields} from '../../../Utils/LogisticUtil';
import StatusBar from '../StatusBar';
import {InfoCircleFilled} from '@ant-design/icons';

const getLogisticsType = (record, params) => {
  if (params?.store_type === 'FAMI') {
    return 'xdelivery';
  }

  if (params?.store_type === '7-ELEVEN') {
    return 'payuni';
  }

  return record.logistics_type;
};

const EditModal = ({record, logistic, setLogistic, params = {}}) => {
  useEffect(() => {
    if (record) {
      setLogistic({
        ...record,
        buyerName: record?.buyer ? record.buyer.name : '-',
        logistics_type: getLogisticsType(record, params),
        logistics_note: record?.logistics_note || '易碎品請勿重摔',
        store_id: params?.st_code || record?.store_id || '',
        store_name: params?.rstore_name || record?.store_name || '',
        store_address: params?.rstore_addr || record?.store_address || '',
      });
    }
  }, [record, setLogistic, params]);

  const updateLogistic = useCallback(
    (obj) => setLogistic((prev) => ({...prev, ...obj})),
    [setLogistic],
  );

  const editableFields = useMemo(() => getEditableFields(logistic), [
    logistic?.logistics_type,
    logistic?.logistics_status,
  ]);

  return (
    <>
      {logistic?.dispatch_status === 'succeeded' && (
        <StatusBar isDispatched>
          <InfoCircleFilled style={{marginRight: 8}} />
          物流單已拋單，若需編輯寄送資訊，請作廢物後重新新增
        </StatusBar>
      )}
      <Section>
        <StyledTitle>寄送方式</StyledTitle>
        <BasicForm
          logistic={logistic}
          updateLogistic={updateLogistic}
          editableFields={editableFields}
        />
      </Section>
      {logistic?.logistics_type !== 'self_pick' && (
        <Section>
          <StyledTitle>寄收資訊</StyledTitle>
          <ShippingInfoForm
            logistic={logistic}
            updateLogistic={updateLogistic}
            type="edit"
            canEdit={editableFields?.allFields}
          />
        </Section>
      )}
      <Section style={{marginBottom: 0}}>
        <StyledTitle>物流備註</StyledTitle>
        <StyledTextArea
          placeholder="請輸入打包、寄送備註"
          value={logistic?.logistics_note}
          onChange={(e) => updateLogistic({logistics_note: e.target.value})}
        />
      </Section>
    </>
  );
};

const StyledTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const Section = styled.div`
  margin-bottom: 34px;
`;

const StyledTextArea = styled(Input.TextArea)`
  && {
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    resize: none;
    height: 88px;
  }
`;

export default EditModal;
