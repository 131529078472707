import React from 'react';
import {Input, Col, Row, Radio, Button} from 'antd';
import styled, {css} from 'styled-components';
import AddressField from '../../../Components/AddressField';
import {useOutlet} from 'reconnect.js';
import RowText from '../../../Widgets/RowText';
const appConfig = require('../../../data.json');

const StyledRowText = (props) => {
  return (
    <RowText
      size="md"
      color="#6B7280"
      containerStyle={{marginBottom: '12px'}}
      labelStyle={{flex: '0 0 100px'}}
      textStyle={{marginLeft: '16px', color: '#000', fontWeight: '500'}}
      noColon
      {...props}
    />
  );
};

const ShippingInfoForm = ({
  logistic,
  updateLogistic,
  id,
  type,
  canEdit = true,
}) => {
  const [actions] = useOutlet('actions');

  const handleSelectStore = () => {
    const url = new URL(`${appConfig.endpoint.webUrl}/logistics/`);
    url.searchParams.append(
      'order_id',
      type === 'add' ? id : logistic?.display_id,
    );
    url.searchParams.append('type', type);
    if (logistic?.id) {
      url.searchParams.append('logistic_id', logistic?.id);
    }
    window.open(actions.getXDeliveryShipMap(encodeURIComponent(url)), '_blank');
  };

  if (!canEdit) {
    return (
      <Row style={{marginTop: 16}} gutter={48}>
        {logistic?.is_delivery_private && (
          <Col span={12}>
            <StyledRowText
              label="寄件人姓名"
              value={logistic?.sender_name || '無'}
            />
            <StyledRowText
              label="寄件人手機"
              value={logistic?.sender_phone || '無'}
            />
            <StyledRowText
              label="寄件人市話"
              value={logistic?.sender_tel || '無'}
            />
            <StyledRowText
              label="寄件人地址"
              value={`${logistic?.sender_zip}${logistic?.sender_city}${logistic?.sender_district}${logistic?.sender_address}`}
            />
          </Col>
        )}
        <Col span={logistic?.is_delivery_private ? 12 : 24}>
          <StyledRowText
            label="收件人姓名"
            value={logistic?.receiver_name || '無'}
          />
          <StyledRowText
            label="收件人手機"
            value={logistic?.receiver_phone || '無'}
          />
          <StyledRowText
            label="收件人市話"
            value={logistic?.receiver_tel || '無'}
          />
          <StyledRowText
            label="收件人地址"
            value={`${logistic?.zip_code}${logistic?.receiver_city}${logistic?.receiver_district}${logistic?.receiver_address}`}
          />
        </Col>
      </Row>
    );
  }

  return (
    <div>
      {!['payuni', 'xdelivery'].includes(logistic?.logistics_type) && (
        <Radio.Group
          onChange={(e) => {
            updateLogistic({is_delivery_private: e.target.value});
          }}
          value={logistic?.is_delivery_private}>
          <Radio value={false}>一般寄送</Radio>
          <Radio value={true}>保密代寄</Radio>
        </Radio.Group>
      )}
      <Row style={{marginTop: 16}} gutter={48}>
        {logistic?.is_delivery_private && (
          <Col span={12}>
            <StyledTitle>寄件人資訊</StyledTitle>
            <InputGroup>
              <StyledLabel required>姓名</StyledLabel>
              <StyledInput
                type="text"
                value={logistic?.sender_name}
                onChange={(e) => updateLogistic({sender_name: e.target.value})}
              />
            </InputGroup>
            <InputGroup>
              <StyledLabel required>地址</StyledLabel>
              <StyledAddressField
                zip_code={logistic?.sender_zip}
                city={logistic?.sender_city}
                district={logistic?.sender_district}
                address={logistic?.sender_address}
                fullWidth
                onChange={(obj) =>
                  updateLogistic({
                    ...(obj.zip_code !== undefined && {
                      sender_zip: obj.zip_code,
                    }),
                    ...(obj.city && {sender_city: obj.city}),
                    ...(obj.district && {
                      sender_district: obj.district,
                    }),
                    ...(obj.address !== undefined && {
                      sender_address: obj.address,
                    }),
                  })
                }
              />
            </InputGroup>
            <InputGroup>
              <StyledLabel required>手機</StyledLabel>
              <StyledInput
                type="text"
                value={logistic?.sender_phone}
                onChange={(e) => updateLogistic({sender_phone: e.target.value})}
              />
            </InputGroup>
            <InputGroup>
              <StyledLabel>市話</StyledLabel>
              <StyledInput
                type="text"
                value={logistic?.sender_tel}
                onChange={(e) => updateLogistic({sender_tel: e.target.value})}
              />
            </InputGroup>
          </Col>
        )}
        <Col span={logistic?.is_delivery_private ? 12 : 24}>
          <StyledTitle>收件方資訊</StyledTitle>
          {['payuni', 'xdelivery'].includes(logistic?.logistics_type) &&
            !logistic?.store_id && (
              <InputGroup>
                <StyledButton onClick={handleSelectStore}>
                  選擇門市
                </StyledButton>
              </InputGroup>
            )}
          <InputGroup>
            <StyledLabel required>姓名</StyledLabel>
            <StyledInput
              type="text"
              value={logistic?.receiver_name}
              onChange={(e) => updateLogistic({receiver_name: e.target.value})}
            />
          </InputGroup>
          {!['payuni', 'xdelivery'].includes(logistic?.logistics_type) && (
            <InputGroup>
              <StyledLabel required>地址</StyledLabel>
              <StyledAddressField
                zip_code={logistic?.zip_code}
                city={logistic?.receiver_city}
                district={logistic?.receiver_district}
                address={logistic?.receiver_address}
                fullWidth
                onChange={(obj) =>
                  updateLogistic({
                    ...(obj.zip_code !== undefined && {
                      zip_code: obj.zip_code,
                    }),
                    ...(obj.city && {receiver_city: obj.city}),
                    ...(obj.district && {
                      receiver_district: obj.district,
                    }),
                    ...(obj.address !== undefined && {
                      receiver_address: obj.address,
                    }),
                  })
                }
              />
            </InputGroup>
          )}
          <InputGroup>
            <StyledLabel required>手機</StyledLabel>
            <StyledInput
              type="text"
              value={logistic?.receiver_phone}
              onChange={(e) => updateLogistic({receiver_phone: e.target.value})}
            />
          </InputGroup>
          <InputGroup>
            <StyledLabel>市話</StyledLabel>
            <StyledInput
              type="text"
              value={logistic?.receiver_tel}
              onChange={(e) => updateLogistic({receiver_tel: e.target.value})}
            />
          </InputGroup>
          {['payuni', 'xdelivery'].includes(logistic?.logistics_type) &&
            logistic?.store_id && (
              <>
                <InputGroup>
                  <StyledLabel>店到店單號</StyledLabel>
                  <div>{logistic.tracking_number || '尚未產生'}</div>
                </InputGroup>
                <InputGroup>
                  <StyledLabel>門市編號</StyledLabel>
                  <div>{logistic?.store_id}</div>
                </InputGroup>
                <InputGroup>
                  <StyledLabel>門市名稱</StyledLabel>
                  <div>{logistic?.store_name}</div>
                </InputGroup>
                <InputGroup>
                  <StyledLabel>門市地址</StyledLabel>
                  <div>{logistic?.store_address}</div>
                </InputGroup>
                <InputGroup>
                  <StyledButton onClick={handleSelectStore}>
                    編輯門市
                  </StyledButton>
                </InputGroup>
              </>
            )}
        </Col>
      </Row>
    </div>
  );
};

const baseInputStyle = css`
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  .ant-space,
  .ant-space-item {
    width: 100%;
  }
`;

const StyledInput = styled(Input)`
  ${baseInputStyle}
  width: 100%;
  height: 48px;
`;

const StyledTitle = styled.h4`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`;

const StyledLabel = styled.h4`
  font-size: 14px;
  font-weight: 400;
  color: #9b9b9b;
  margin-bottom: 8px;
  ${(props) =>
    props.required &&
    css`
      &::before {
        content: '*';
        color: #9b9b9b;
        margin-right: 4px;
      }
    `}
`;

const StyledAddressField = styled(AddressField)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  &&& {
    padding: 12px 16px !important;
    max-width: 98px;
    height: 48px;
    border-radius: 12px;
    border: 1px solid #227fe9;
    color: #227fe9;
    line-height: 22px;
  }
`;

export default ShippingInfoForm;
