import React, {useState} from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {getOrderId, PAYMENT_STATUS, getPaymentType} from '../Utils/OrderUtil';
import {EXTRA_ORDER_TYPE} from '../dictionary';
import Block from '../Widgets/Block';
import {useOutlet} from 'reconnect.js/build';
import Anchor from '../Widgets/Anchor';

export default function ExtraOrderBlock(props) {
  const {order, records} = props;
  const [actions] = useOutlet('actions');

  return (
    <Block>
      <h2>補收款訂單資訊</h2>
      <div className="divider" />
      <Ant.Table
        mounted={true}
        dataSource={records}
        columns={[
          {
            title: '補收款單號',
            render: (extraOrder) => (
              <Anchor to={`/order/?id=${extraOrder.id}`}>{`# ${getOrderId(
                extraOrder,
              )}`}</Anchor>
            ),
          },
          {
            title: '補收類別',
            render: (extraOrder) => EXTRA_ORDER_TYPE[extraOrder.extra_type],
          },
          {
            title: '補收原因',
            render: (extraOrder) => extraOrder.description,
          },
          {
            title: '補收金額(含稅)',
            render: (extraOrder) => extraOrder.amount,
          },
          {
            title: '付款狀態',
            render: (extraOrder) => PAYMENT_STATUS[extraOrder.payment_status],
          },
          {
            title: '付款方式',
            render: (extraOrder) => getPaymentType(extraOrder),
          },
        ]}
        pagination={false}
      />
    </Block>
  );
}
