import React from 'react';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from '@react-pdf/renderer';
const appConfig = require('../data.json');

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'NotoSansTC',
    src: '/MiSans-Normal.ttf',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 40,
  },
  title: {
    textAlign: 'center',
    marginBottom: 10,
  },
});

// Create Document Component
const TestDocument = (props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text
          style={[
            styles.title,
            {whiteSpace: 'wrap', lineHeight: 1.2, marginBottom: 30},
          ]}>
          這是民
        </Text>
      </Page>
    </Document>
  );
};

export {TestDocument};
