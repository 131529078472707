import React from 'react';
import styled from 'styled-components';
const appConfig = require('../data.json');

export default function Alert({
  color = '#dd8022',
  backgroundColor = false,
  children,
  style = {},
}) {
  return (
    <Wrapper color={color} bgColor={backgroundColor} style={style}>
      <div className="dot" />
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
  border: solid 0.5px ${(props) => props.color};
  background-color: ${(props) => props.bgColor || props.color + '22'};
  border-radius: 8px;
  padding: 8px 15px;

  & .dot {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: ${(props) => props.color};
    margin-right: 10px;
  }
`;
