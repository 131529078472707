import {message} from 'antd';
const appConfig = require('../../../data.json');

// 編輯跟新增檢查邏輯可以抽出
export const handleAddSubmit = async (
  logistic,
  actions,
  order,
  setLoading,
  handleModalOnSubmit,
) => {
  setLoading(true);
  let params = {
    ...logistic,
    order: order.id,
  };

  if (logistic.logistics_type === 'sf' && !logistic.is_delivery_private) {
    const {
      zip_code,
      city,
      district,
      address,
      name,
      tel,
    } = appConfig.logisticsSenderInfo;

    params.sender_zip = zip_code;
    params.sender_city = city;
    params.sender_district = district;
    params.sender_address = address;
    params.sender_name = name;
    params.sender_tel = tel;
  }

  if (!['payuni', 'xdelivery'].includes(logistic.logistics_type)) {
    delete params.store_id;
    delete params.store_name;
    delete params.store_address;
  }

  if (!params.receiver_phone && logistic.logistics_type !== 'self_pick') {
    message.error('收件人手機號碼尚未填寫');
    setLoading(false);
    return;
  }

  if (params.receiver_phone && !params.receiver_tel) {
    delete params.receiver_tel;
  }

  delete params.id;

  try {
    await actions.createLogistic(params);
    message.success('出貨單成立完成');
    handleModalOnSubmit();
  } catch (err) {
    message.error('出貨單成立錯誤');
    console.warn(err);
  }
  setLoading(false);
};

export const handleEditSubmit = async (
  logistic,
  actions,
  setLoading,
  handleModalOnSubmit,
) => {
  setLoading(true);
  let params = {...logistic};

  if (logistic.logistics_type === 'sf' && !logistic.is_delivery_private) {
    const {
      zip_code,
      city,
      district,
      address,
      name,
      tel,
    } = appConfig.logisticsSenderInfo;

    params.sender_zip = zip_code;
    params.sender_city = city;
    params.sender_district = district;
    params.sender_address = address;
    params.sender_name = name;
    params.sender_tel = tel;
  }

  if (!['payuni', 'xdelivery'].includes(logistic.logistics_type)) {
    delete params.store_id;
    delete params.store_name;
    delete params.store_address;
  }

  delete params.order;

  if (!params.receiver_phone && logistic.logistics_type !== 'self_pick') {
    message.error('收件人手機號碼尚未填寫');
    setLoading(false);
    return;
  }

  if (params.receiver_phone && !params.receiver_tel) {
    delete params.receiver_tel;
  }

  try {
    await actions.editLogistic(params);
    message.success('出貨單更新完成');
    handleModalOnSubmit();
  } catch (err) {
    message.error('更新出貨單錯誤');
    console.warn(err);
  }
  setLoading(false);
};

export const handleCopySubmit = async (
  id,
  copyQuantity,
  actions,
  setLoading,
  handleModalOnSubmit,
) => {
  setLoading(true);
  console.log(id);
  try {
    actions.duplicateLogisticByID({id, quantity: copyQuantity});
    message.success(`已複製 ${copyQuantity} 筆物流單`);
    handleModalOnSubmit();
  } catch (err) {
    message.error('複製物流單錯誤');
    console.warn(err);
  }
  setLoading(false);
};

export const handleDispatchSubmit = async (
  record,
  actions,
  setLoading,
  handleModalOnSubmit,
) => {
  setLoading(true);
  try {
    await actions.dispatchLogisticByID(record.id);
    message.success('拋送物流單成功');
    handleModalOnSubmit();
  } catch (err) {
    message.error(err?.detail || '拋送物流單失敗');
    console.warn(err);
  }
  setLoading(false);
};

export const handleDispatchBatchSubmit = async (
  record,
  actions,
  setLoading,
  handleModalOnSubmit,
) => {
  setLoading(true);
  try {
    const ids = record.map((item) => item.id);
    await actions.batchDispatchLogistic({logistics_ids: ids});
    message.success('拋送物流單成功');
    handleModalOnSubmit();
  } catch (err) {
    message.error('拋送物流單失敗');
    console.warn(err);
  }
  setLoading(false);
};

export const handleCancelSubmit = async (
  record,
  actions,
  setLoading,
  handleModalOnSubmit,
) => {
  setLoading(true);
  try {
    await actions.cancelLogisticByID(record.id);
    message.success('作廢物流單成功');
    handleModalOnSubmit();
  } catch (err) {
    message.error('作廢物流單失敗');
    console.warn(err);
  }
  setLoading(false);
};
