const JSZip = require('jszip');
const FileSaver = require('file-saver');

async function getFile(url) {
  const resp = await fetch(url);
  const blob = await resp.blob();
  return {
    filename: url.slice(url.lastIndexOf('/') + 1),
    blob,
  };
}

async function downloadZip(urls = [], options = {}) {
  const {zipName = 'download.zip'} = options;
  const zip = new JSZip();
  // zip.file('test.txt', 'test\r\nroy');

  try {
    const results = await Promise.all(urls.map((url) => getFile(url)));
    for (let {filename, blob} of results) {
      zip.file(filename, blob);
    }

    content = await zip.generateAsync({type: 'blob'});

    FileSaver.saveAs(content, zipName);
  } catch (err) {
    console.warn('download zip error', err);
  }
}

module.exports = {
  downloadZip,
};
