const Uppy = require("@uppy/core");
const AwsS3Multipart = require("@uppy/aws-s3-multipart");

async function post({ path, data }) {
  let response = await fetch(`${path}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });

  if (`${response.status}`[0] === "2") {
    return response.json();
  }

  return Promise.reject({ status: response.status });
}

class MultipartUploader {
  constructor({
    host,
    debug = false,
    onProgress = () => {},
    onStart = () => {},
    onComplete = () => {}
  }) {
    if (!host) {
      throw "host of multipart upload is neccessary";
    }

    this.uppy = new Uppy({
      debug
    });
    this.jwt_token = null;

    let params = {
      bucket: "",
      uploadId: "",
      key: ""
    };

    this.uppy.use(AwsS3Multipart, {
      getChunkSize: file => 1024 * 1024 * 5, // 5MB
      createMultipartUpload: async file => {
        let result = await post({
          path: `${host}/storage/multipart/create?token=${this.jwt_token}`,
          data: {
            key: file.name,
            "Content-Type": file.type
          }
        });
        //{ Bucket, UploadId, Key, ResponseMetadata }
        params = {
          bucket: result.Bucket,
          uploadId: result.UploadId,
          key: result.Key
        };
        return params;
      },
      prepareUploadPart: async (file, partData) => {
        let result = await post({
          path: `${host}/storage/presigned/upload-part?token=${this.jwt_token}`,
          data: {
            bucket: params.bucket,
            key: partData.key,
            upload_id: partData.uploadId,
            part_number: partData.number
          }
        });

        return result;
      },
      abortMultipartUpload: async (file, { uploadId, key }) => {
        let result = await post({
          path: `${host}/storage/multipart/abort?token=${this.jwt_token}`,
          data: {
            bucket: params.bucket,
            upload_id: uploadId,
            key
          }
        });
      },
      completeMultipartUpload: async (file, { uploadId, key, parts }) => {
        let result = await post({
          path: `${host}/storage/multipart/complete?token=${this.jwt_token}`,
          data: {
            upload_id: uploadId,
            key,
            parts
          }
        });

        onComplete({ url: result.expected });

        return result;
      },

      listParts: async (file, { uploadId, key }) => {
        let result = await post({
          path: `${host}/storage/multipart/list?token=${this.jwt_token}`,
          data: {
            upload_id: uploadId,
            key
          }
        });

        return result;
      }
    });

    this.uppy.on("upload-progress", (file, progress) => {
      let percentage = (
        (progress.bytesUploaded / progress.bytesTotal) *
        100
      ).toFixed();
      onProgress(percentage);
    });

    this.uppy.on("upload", () => {
      onStart();
    });
  }

  setToken(token) {
    // set token
    this.jwt_token = token;
  }

  async upload(file) {
    // add file
    let fileId = this.uppy.addFile({
      source: "file input",
      name: file.name,
      type: file.type,
      data: file
    });

    let result = await this.uppy.upload();
    console.log("RETURN FROM UPLOAD", result);
    return result;
  }

  reset() {
    this.uppy.cancelAll();
  }

  close() {
    this.uppy.off("upload-progress", () => {});
    this.uppy.off("upload", () => {});
    this.uppy.close();
  }
}

module.exports = MultipartUploader;
