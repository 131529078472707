import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {isStaff, isReviewer, isVIP} from '../Domain/RoleValidator';
import LogisticDialog from '../Dialogs/LogisticDialog';
import {isOrderDisabled} from '../Utils/OrderUtil';
import {useOutlet} from 'reconnect.js';
import {message, Collapse} from 'antd';
import {CopyOutlined} from '@ant-design/icons';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import Anchor from '../Widgets/Anchor';
import Center from '../Widgets/Center';
import Empty from '../Widgets/Empty';
import Tag from '../Widgets/Tag';
import LogisticCenterModal from '../Templates/LogisticCenter/Modals';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import {displayStatus} from '../Utils/LogisticUtil';
import {DELIVERY_TYPE} from '../dictionary';

const appConfig = require('../data.json');
const qs = require('query-string');

function Item({record, onItemClick}) {
  return (
    <React.Fragment>
      <div className="row">
        <h4>物流單編號</h4>
        <Anchor type="button" onClick={onItemClick}>{`#${record.id}`}</Anchor>
      </div>
      <div className="row">
        <h4>物流狀態</h4>
        <div>{displayStatus(record)}</div>
      </div>
      <div className="row">
        <h4>運送方式</h4>
        <div>{DELIVERY_TYPE[record.logistics_type]}</div>
        {record.is_delivery_private &&
        record.logistics_type !== 'self_pick' &&
        record.logistics_type !== 'ezship' ? (
          <Tag shape="circle" color="#d0543c" style={{marginLeft: 5}}>
            保
          </Tag>
        ) : null}
      </div>
      <div className="row">
        <h4>追蹤單號</h4>
        <div>{record.tracking_number || '---'}</div>
      </div>
      <div className="row">
        <h4>建立時間</h4>
        <div>
          {record.created.slice(0, 10)} {record.created.slice(11, 16)}
        </div>
      </div>
      <div className="row">
        <h4>預計寄貨時間</h4>
        <div>
          {record.shipping_time ? record.shipping_time.slice(0, 10) : '---'}
        </div>
      </div>
      <div className="row">
        <h4>指定收貨時間</h4>
        <div>
          {record.receipt_time
            ? record.receipt_time.slice(0, 10) +
              ' ' +
              record.receipt_time.slice(11, 16)
            : '未指定'}
        </div>
      </div>
    </React.Fragment>
  );
}

export default function LogisticBlock(props) {
  const {order, onUpdate, params, clearUrlParams} = props;
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [logisticCenterModal, setLogisticCenterModal] = useState(false);
  const [logisticCenterModalType, setLogisticCenterModalType] = useState(null);
  const [actions] = useOutlet('actions');
  const [profile] = useOutlet('user');
  const [selected, setSelected] = useState(null);
  const [urlParams, setUrlParams] = useState({});

  const getRecords = useCallback(async () => {
    setLoading(true);
    try {
      const {results} = await actions.getLogistics({
        order: order.id,
        ordering: '-created',
      });
      setRecords(results);
    } catch (ex) {
      message.warning('取得物流單錯誤');
      console.warn(ex);
    }
    setLoading(false);
  }, [order]);

  const openLogisticCenterModal = (type, instance = {}) => {
    setLogisticCenterModalType(type);
    setLogisticCenterModal(true);
    if (type === 'dispatch_batch') {
      setSelected(records);
    } else {
      setSelected(instance);
    }
  };

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  useEffect(() => {
    const parsedParams = qs.parse(params);
    if (parsedParams && Object.keys(parsedParams).length > 1) {
      setUrlParams(parsedParams);
      if (parsedParams.type === 'edit') {
        const record = records.find(
          (r) => r.id === Number(parsedParams.logistic_id),
        );
        setSelected(record);
      }
      setOpenDialog(true);
    }
  }, [params, records]);

  return (
    <Block>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div>
          <h2>出貨及物流資訊</h2>
          <div className="subtitle">訂單付款完成，會自動產生。</div>
        </div>
        <div style={{flex: 1}} />
        {appConfig.enableNewLogisticsCenter && !isOrderDisabled(order) && (
          <Button
            type="primary"
            size="small"
            style={{marginRight: 10}}
            onClick={() => {
              const isAllDefaultStatus = records.every(
                (record) => record.logistics_status === 'default',
              );
              if (!isAllDefaultStatus) {
                message.warning(
                  '物流單中有狀態非「未處理」的單，請至列表頁做批次拋單',
                );
                return;
              }
              openLogisticCenterModal('dispatch_batch');
            }}>
            拋單
          </Button>
        )}
        {(isStaff(profile) || isReviewer(profile)) && !isOrderDisabled(order) && (
          <Button
            type="primary"
            size="small"
            style={{marginRight: 10}}
            onClick={() => {
              if (appConfig.enableNewLogisticsCenter) {
                openLogisticCenterModal('add');
              } else {
                setOpenDialog(true);
              }
            }}>
            新增出貨單
          </Button>
        )}
        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={getRecords}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </div>
      <div className="divider" />
      {records.length > 0 ? (
        <div>
          <Collapse defaultActiveKey={['0']}>
            {records.map((record, idx) => (
              <Collapse.Panel
                key={idx}
                header={
                  <>
                    <div style={{display: 'inline-flex', fontSize: 16}}>
                      <div style={{marginRight: 10}}>物流單編號</div>
                      <Anchor
                        type="button"
                        onClick={(e) => {
                          if (appConfig.enableNewLogisticsCenter) {
                            openLogisticCenterModal('edit', record);
                          } else {
                            setSelected(record);
                            setOpenDialog(true);
                          }
                        }}>{`#${record.id}`}</Anchor>
                    </div>
                    {appConfig.enableNewLogisticsCenter &&
                      !isOrderDisabled(order) && (
                        <CopyOutlined
                          style={{
                            position: 'absolute',
                            top: '50%',
                            right: '16px',
                            transform: 'translateY(-50%)',
                            fontSize: 18,
                            color: '#438de2',
                          }}
                          onClick={() =>
                            openLogisticCenterModal('copy', record)
                          }
                        />
                      )}
                  </>
                }>
                <Item
                  record={record}
                  onItemClick={() => {
                    if (appConfig.enableNewLogisticsCenter) {
                      openLogisticCenterModal('edit', record);
                    } else {
                      setSelected(record);
                      setOpenDialog(true);
                    }
                  }}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      ) : (
        <Center>
          <Empty />
        </Center>
      )}

      {openDialog && (
        <LogisticDialog
          closeDialog={() => {
            setSelected(null);
            setOpenDialog(false);
            setUrlParams({});
            clearUrlParams();
          }}
          onUpdate={() => {
            getRecords();
            onUpdate();
          }}
          order={order}
          selected={selected}
          params={urlParams}
        />
      )}
      {appConfig.enableNewLogisticsCenter && (
        <LogisticCenterModal
          visible={logisticCenterModal}
          type={logisticCenterModalType}
          record={selected}
          params={urlParams}
          orderFromDetailPage={order}
          destroyOnClose
          reset={clearUrlParams}
          onClose={() => {
            setLogisticCenterModal(false);
            setLogisticCenterModalType(null);
            setSelected(null);
          }}
          onUpdate={onUpdate}
        />
      )}
    </Block>
  );
}
