import React from 'react';
import SimpleLogisticSummary from '../SimpleLogisticSummary';

const DispatchModal = ({record}) => {
  const recordArray = Array.isArray(record) ? record : [record];
  return (
    <>
      <p style={{fontWeight: 500}}>拋單後將無法編輯寄收資訊，請確定後再拋單</p>
      <SimpleLogisticSummary data={recordArray} />
    </>
  );
};

export default DispatchModal;
