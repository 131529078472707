import React from 'react';
import SimpleLogisticSummary from '../SimpleLogisticSummary';

const CancelModal = ({record}) => {
  return (
    <>
      <p style={{fontWeight: 500}}>物流單作廢後將無法復原，您確定要作廢嗎？</p>
      <SimpleLogisticSummary data={[record]} />
    </>
  );
};

export default CancelModal;
