import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {ErrSpec, errorHandler} from '../errors';
import {pricingTypes} from '../pricingTypes';

function findSpecByProductName({productSpec, name}) {
  for (let t of pricingTypes) {
    if (!!productSpec[t]) {
      for (let p of productSpec[t]) {
        if (p.name === name) {
          return p;
        }
      }
    }
  }
}

function getOptionByName({param, name, material}) {
  const selectedOption = material
    ? param.options.find((o) => o.material === material)
    : param.options.find((o) => o.name === name);

  if (!selectedOption) {
    throw new ErrSpec(`無法在商品規格找到：${name}`);
  }

  return selectedOption;
}

function CartItem({productSpec, order, orderItem}) {
  const [cartData] = useState(JSON.parse(order.cart));
  const itemInCart = cartData.items[orderItem.item_index];

  return (
    <Wrapper>
      <div
        style={{
          marginBottom: 15,
          display: 'flex',
          borderTop: '1px solid gray',
          borderLeft: '1px solid #ddd',
          borderBottom: '1px solid #ddd',
        }}>
        <div
          style={{
            flex: 0,
            flexBasis: 150,
            display: 'flex',
            flexDirection: 'column',
            borderRight: '1px solid #ddd',
          }}>
          <div
            style={{
              padding: 10,
              flex: 0,
              height: 30,
              textAlign: 'center',
              padding: 5,
              borderBottom: '1px solid #ccc',
            }}>
            商品
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 5,
            }}>
            {(order && order.custom_note) || itemInCart.name}
          </div>
        </div>

        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            borderRight: '1px solid #ddd',
          }}>
          <div
            style={{
              padding: 10,
              flex: 0,
              height: 30,
              textAlign: 'center',
              padding: 5,
              borderBottom: '1px solid #ccc',
            }}>
            規格
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              padding: 5,
            }}>
            {renderProductConfig(productSpec, order, orderItem)}
          </div>
        </div>

        <div
          style={{
            flex: 0,
            flexBasis: 100,
            display: 'flex',
            flexDirection: 'column',
            borderRight: '1px solid #ddd',
          }}>
          <div
            style={{
              padding: 10,
              flex: 0,
              height: 30,
              textAlign: 'center',
              padding: 5,
              borderBottom: '1px solid #ccc',
            }}>
            價格
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 5,
            }}>
            {itemInCart.amount === itemInCart.price
              ? (itemInCart.amount * 1.05).toFixed(0)
              : itemInCart.price}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export function renderProductConfig(productSpec, order, orderItem) {
  const cartData = JSON.parse(order.cart);
  const itemInCart = cartData.items[orderItem.item_index];
  // const spec = findSpecByProductName({ productSpec, name: itemInCart.name });
  if (order.order_type === 'custom') {
    itemInCart.name = '自定義商品';
  }
  const spec = useRef(
    findSpecByProductName({productSpec, name: itemInCart.name}),
  ).current;

  const itemConfig = itemInCart.config;

  if (!spec) {
    return (
      <div>
        <div>商品規格版本：{productSpec?.version || '-'}</div>
        <div>找不到{itemInCart.name}的規格</div>
      </div>
    );
  }

  return (
    <div>
      {Object.keys(itemConfig).map((k) => {
        const param = spec[k];

        if (!param) {
          return (
            <div key={k}>
              {itemInCart.name}的商品規格沒有對應的{k}的選項
            </div>
          );
        }

        if (param.type === 'single') {
          const option = getOptionByName({param, name: itemConfig[k]});
          return (
            <div key={k}>
              {`${param.label || k}: ${option.label || option.name}`}
            </div>
          );
        } else if (param.type === 'multiple') {
          return (
            <>
              <div key={k}>{`${param.label || k}: `}</div>

              {itemConfig[k].length > 0 && (
                <ul style={{marginLeft: 15}}>
                  {itemConfig[k].map((name) => {
                    const option = getOptionByName({param, name});
                    return <li key={k}>{`${option.label || name}`}</li>;
                  })}
                </ul>
              )}
            </>
          );
        } else if (param.type === 'number') {
          return <div key={k}>{`${param.label || k}: ${itemConfig[k]}`}</div>;
        } else if (param.type === 'group-number') {
          const option = getOptionByName({param, name: itemConfig[k].name});
          return (
            <div key={k}>
              {`${param.label || k}: ${option.label || option.name} ${
                itemConfig[k].quantity
              }`}
            </div>
          );
        } else if (param.type === 'master') {
          return (
            <div key={k}>
              <div>{`材質: ${itemConfig[k].material}`}</div>
              <div>{`數量: ${itemConfig[k].quantity}`}</div>
              <div>{`尺寸: ${itemConfig[k].size}`}</div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

const Wrapper = styled.div``;

export default CartItem;
