import React, {Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Close} from '@styled-icons/material/Close';
import * as Ant from 'antd';
import {RETURN_STATUS, RETURN_REASON} from '../dictionary';
const appConfig = require('../data.json');
import {errorHandler} from '../errors';
import {useOutlet} from 'reconnect.js';
import Row from '../Widgets/Row';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import {Attachment} from '@styled-icons/icomoon/Attachment';

export default function ReturnApplicationDialog(props) {
  const {
    returnApp,
    order,
    closeDialog,
    openRefundDialog = () => {},
    onUpdate,
  } = props;
  const [record, setRecord] = useState({
    id: null,
    order: null,
    status: '',
    note: '',
    file: '',
    rejected_reason: RETURN_REASON[0],
    ...returnApp,
  });
  const [newFile, setNewFile] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const isEdit = !!returnApp;
  const [actions] = useOutlet('actions');

  const _setRecord = useCallback(
    (obj) => setRecord((prev) => ({...prev, ...obj})),
    [],
  );

  const submit = useCallback(
    async (record) => {
      actions.setLoading(true);
      try {
        if (record.id) {
          await actions.editReturnApp({
            id: record.id,
            status: record.status,
            note: record.note,
            file: newFile,
            item_index: record.item_index,
            rejected_reason: record.rejected_reason,
          });
        } else {
          await actions.createReturnApp({
            order: order.id,
            status: record.status,
            note: record.note,
            file: newFile,
            item_index: orderItems.length > 0 ? orderItems[0].item_index : 0,
            rejected_reason: record.rejected_reason,
          });
        }

        onUpdate();
        setNewFile(null);
        closeDialog();
      } catch (err) {
        errorHandler(err, `${record.id ? '編輯' : '新增'}售後單錯誤`);
      }
      actions.setLoading(false);
    },
    [actions, order, newFile, orderItems],
  );

  return (
    <Ant.Modal
      visible={true}
      title={isEdit ? `編輯售後服務單` : `新增售後服務單`}
      footer={
        <Row
          style={{
            justifyContent: 'flex-end',
          }}>
          <Button style={{marginRight: 10}} onClick={() => closeDialog()}>
            取消
          </Button>
          <Button type="primary" onClick={() => submit(record)}>
            確定
          </Button>
        </Row>
      }
      closeIcon={
        <Close style={{position: 'absolute', right: 20, top: 20}} size={20} />
      }
      onCancel={closeDialog}
      width={800}>
      <Block>
        <div style={{padding: 0}}>
          {isEdit && (
            <Fragment>
              <div className="row">
                <h4>售後服務單編號</h4>
                <div># {record.id}</div>
              </div>
              <div className="row">
                <h4>訂單編號</h4>
                <div># {record.display_id}</div>
              </div>
              <div className="row">
                <h4>處理</h4>
                <Ant.Radio
                  checked={record.status === 'rejected'}
                  disabled={record.status !== 'pending'}
                  onClick={() => {
                    if (window.confirm('確定要拒絕退款？')) {
                      _setRecord({status: 'rejected'});
                      submit({
                        ...record,
                        status: 'rejected',
                      });
                    }
                  }}>
                  拒絕退款
                </Ant.Radio>
                <Ant.Radio
                  checked={record.status === 'returning'}
                  disabled={record.status !== 'pending'}
                  onClick={() => {
                    openRefundDialog();
                    closeDialog();
                  }}>
                  退款
                </Ant.Radio>
                {record.status === 'return_completed' && (
                  <div>{RETURN_STATUS[record.status]}</div>
                )}
              </div>
            </Fragment>
          )}
          <div className="row">
            <h4>退貨原因</h4>
            <Ant.Select
              value={record.rejected_reason}
              onChange={(value) => _setRecord({rejected_reason: value})}>
              {RETURN_REASON.map((reason) => (
                <Ant.Select.Option value={reason} key={reason}>
                  {reason}
                </Ant.Select.Option>
              ))}
            </Ant.Select>
          </div>
          <div className="row">
            <h4>備註</h4>
            <Ant.Input.TextArea
              value={record.note ? record.note : ''}
              style={{marginRight: 10}}
              onChange={(e) => _setRecord({note: e.target.value})}
            />
          </div>
          <div className="row">
            <h4>檔案</h4>
            <div>
              <FileInput>
                <input
                  type="file"
                  onChange={(e) => setNewFile(e.target.files[0])}
                />
                <span>選擇檔案</span>
              </FileInput>
              {newFile ? (
                <Row>
                  <Attachment color="#aaa" size={16} style={{marginRight: 5}} />
                  <div style={{color: '#aaa'}}>{newFile.name}</div>
                </Row>
              ) : (
                <Row>
                  <Attachment
                    color={appConfig.colors.sub}
                    size={16}
                    style={{marginRight: 5}}
                  />
                  <a
                    href={record.file}
                    target="_blank"
                    style={{color: appConfig.colors.sub}}>
                    {record.file}
                  </a>
                </Row>
              )}
            </div>
          </div>
        </div>
      </Block>
    </Ant.Modal>
  );
}

const FileInput = styled.label`
  display: inline-block;
  color: white;
  background-color: ${appConfig.colors.sub};
  height: 30px;
  padding: 0 15px;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  margin-right: 5px;
  margin-bottom: 5px;
  & > input {
    position: absolute;
    display: none;
  }
  & > span {
    line-height: 1.9rem;
  }

  &:hover {
    opacity: 0.7;
  }
`;
