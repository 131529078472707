import React, {useMemo, useCallback, useEffect} from 'react';
import {Input} from 'antd';
import styled from 'styled-components';
import {parseCartData} from '../../../Utils/OrderUtil';
import BasicForm from '../Forms/Basic';
import ShippingInfoForm from '../Forms/Shipping';

const appConfig = require('../../../data.json');

const getLogisticsType = (deliveryConfig, params) => {
  if (params?.store_type === 'FAMI') {
    return 'xdelivery';
  }

  if (params?.store_type === '7-ELEVEN') {
    return 'payuni';
  }
  // 關閉 7-11 寄件，暫時的判斷
  if (deliveryConfig?.delivery_type !== 'payuni') {
    return deliveryConfig?.delivery_type;
  }
  return appConfig.supportLogistics[0];
};

const initializeLogistic = (cart, params = {}) => {
  if (cart) {
    let {deliveryConfig} = cart?.config;

    return {
      is_delivery_private: false,
      sender_zip: '',
      sender_city: null,
      sender_district: null,
      sender_address: '',
      sender_name: '',
      sender_phone: '',
      sender_tel: '',
      logistics_status: 'default',
      tracking_number: '',
      receipt_time: null,
      shipping_time: null,
      created: null,
      buyerName: '',
      receiver_city: null,
      receiver_district: null,
      receiver_address: '',
      receiver_name: '',
      receiver_phone: '',
      receiver_tel: '',
      ...deliveryConfig,
      zip_code: deliveryConfig?.zip_code || deliveryConfig?.zip || '',
      logistics_type: getLogisticsType(deliveryConfig, params),
      logistics_note: deliveryConfig?.delivery_note || '易碎品請勿重摔',
      store_id: params?.st_code || '',
      store_name: params?.rstore_name || '',
      store_address: params?.rstore_addr || '',
    };
  }
};

const AddModal = ({
  order,
  orderID,
  setOrderID,
  params = {},
  logistic,
  setLogistic,
}) => {
  const cart = useMemo(() => (order ? parseCartData(order) : null), [order]);

  const updateLogistic = useCallback(
    (obj) => setLogistic((prev) => ({...prev, ...obj})),
    [setLogistic],
  );

  useEffect(() => {
    if (order) {
      setLogistic(initializeLogistic(cart, params));
    }
  }, [order, cart, setLogistic, params]);

  return (
    <div>
      {order ? (
        <>
          <Section>
            <StyledTitle>寄送方式</StyledTitle>
            <BasicForm logistic={logistic} updateLogistic={updateLogistic} />
          </Section>
          {!['self_pick', 'special_car'].includes(logistic?.logistics_type) && (
            <Section>
              <StyledTitle>寄收資訊</StyledTitle>
              <ShippingInfoForm
                logistic={logistic}
                updateLogistic={updateLogistic}
                id={order?.display_id || ''}
                type="add"
              />
            </Section>
          )}
          <Section style={{marginBottom: 0}}>
            <StyledTitle>物流備註</StyledTitle>
            <StyledTextArea
              placeholder="請輸入打包、寄送備註"
              value={logistic?.logistics_note}
              onChange={(e) => updateLogistic({logistics_note: e.target.value})}
            />
          </Section>
        </>
      ) : (
        <StyledInput
          placeholder="請輸入訂單編號"
          value={orderID}
          onChange={(e) => setOrderID(e.target.value)}
        />
      )}
    </div>
  );
};

const StyledInput = styled(Input)`
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  width: 100%;
  height: 48px;
`;

const StyledTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const Section = styled.div`
  margin-bottom: 34px;
`;

const StyledTextArea = styled(Input.TextArea)`
  && {
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    resize: none;
    height: 88px;
  }
`;

export default AddModal;
