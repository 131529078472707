import React, {useEffect, useState, useMemo} from 'react';
import Block from '../../Widgets/Block';
import Row from '../../Widgets/Row';
import * as Ant from 'antd';
import {
  getDisplayState,
  getCalculation,
  isOrderDisabled,
  getOrderId,
} from '../../Utils/OrderUtil';
import {
  PAYMENT_TYPE,
  PAYMENT_STATUS,
  PAYMENT_SUB_TYPE,
  ORDER_STATE,
  ORDER_TYPE,
  EXTRA_ORDER_TYPE,
} from '../../dictionary';
import IconButton from '../../Widgets/IconButton';
import {Edit} from '@styled-icons/boxicons-solid/Edit';
import Anchor from '../../Widgets/Anchor';
import {UISTATE as ORDER_DIALOG_TYPE} from '../../Dialogs/OrderDialog';
import {useOutlet} from 'reconnect.js';
const appConfig = require('../../data.json');

export default function ExtraOrderDataBlock(props) {
  const {order} = props;
  const [actions] = useOutlet('actions');
  const [baseOrder, setBaseOrder] = useState();

  useEffect(() => {
    if (order && order.base_order) {
      (async () => {
        try {
          let resp = await actions.getOrder(order.base_order);
          setBaseOrder(resp);
        } catch (err) {
          console.warn(err);
        }
      })();
    }
  }, [order]);

  return (
    <Block>
      <h2> 補收款資訊</h2>
      <div className="divider" />
      <div>
        <div className="row">
          <h4>原訂單編號</h4>
          <Anchor to={`/order/?id=${baseOrder?.id}`}>
            {getOrderId(baseOrder)}
          </Anchor>
        </div>
        {order.period_order && (
          <div className="row">
            <h4>訂閱制委託單</h4>
            <Anchor to={`/period/?id=${order.period_order}`}>
              {order.period_order}
            </Anchor>
          </div>
        )}

        <div className="row">
          <h4>補收類別</h4>
          <div>{EXTRA_ORDER_TYPE[order.extra_type]}</div>
        </div>
        <div className="row">
          <h4>補收原因</h4>
          <div>{order.description}</div>
        </div>
        <div className="row">
          <h4>補收金額</h4>
          <div>{order.amount} 元(含稅)</div>
        </div>

        {order.period_order && order.payment_status === 'success' && (
          <div className="row">
            <Hint type="warning">已付款，請至委託單執行終止</Hint>
          </div>
        )}
      </div>
    </Block>
  );
}
