import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {isStaff, isReviewer} from '../Domain/RoleValidator';
import RetrunDialog from '../Dialogs/ReturnApplicationDialog';
import RefundDialog from '../Dialogs/RefundDialog';
import {isReturnAppDisabled} from '../Utils/OrderUtil';
import Button from '../Widgets/Button';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../errors';
import Block from '../Widgets/Block';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import Center from '../Widgets/Center';
import Empty from '../Widgets/Empty';
import Anchor from '../Widgets/Anchor';
import Divider from '../Widgets/Divider';
import {
  RETURN_STATUS,
  RETURN_REASON,
  REFUND_STATUS,
  REFUND_TYPES,
} from '../dictionary';
import {getProfileDisplay} from '../Utils/getProfileDisplay';
const appConfig = require('../data.json');

export default function ReturnBlock(props) {
  const {order} = props;
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRefundDialog, setOpenRefundDialog] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRefund, setSelectedRefund] = useState(null);
  const [records, setRecords] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [actions] = useOutlet('actions');
  const [profile] = useOutlet('user');

  const getRecords = useCallback(async () => {
    try {
      const resp = await actions.getReturnApps({
        order: order.id,
      });
      setRecords(resp.results);
    } catch (ex) {
      errorHandler(ex, '取得售後單錯誤');
    }
  }, [order, actions]);

  const getRefunds = useCallback(async () => {
    try {
      const resp = await actions.getRefunds({order: order.id});
      setRefunds(resp.results);
    } catch (ex) {
      errorHandler(ex, '取得退貨單錯誤');
    }
  }, [actions, order]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([getRefunds(), getRecords()]);
      setLoading(false);
    })();
  }, [getRefunds, getRecords]);

  const {record, refund} = useMemo(() => {
    return {
      record: records.length > 0 ? records[0] : null,
      refund: refunds.length > 0 ? refunds[0] : null,
    };
  }, [records, refunds]);

  return (
    <Block>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h2>售後服務單</h2>

        <div style={{flex: 1}} />
        <Button
          type="primary"
          size="small"
          style={{marginRight: 10}}
          disabled={
            !(isStaff(profile) || isReviewer(profile)) ||
            records.length > 0 ||
            isReturnAppDisabled(order)
          }
          onClick={() => {
            setOpenDialog(true);
            setSelectedRecord(null);
          }}>
          新增售後單
        </Button>
        <Button
          shape="circle"
          onClick={() => {
            getRefunds();
            getRecords();
          }}
          style={{border: 0}}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </div>

      <div className="divider" />

      {record ? (
        <div>
          <div className="row">
            <h4>售後單編號</h4>
            <Anchor
              type="button"
              onClick={() => {
                setSelectedRecord(record);
                setOpenDialog(true);
              }}>
              # {record.id}
            </Anchor>
          </div>
          <div className="row">
            <h4>處理方式</h4>
            <div>{RETURN_STATUS[record.status]}</div>
          </div>
          <div className="row">
            <h4>建立時間</h4>
            <div>
              {record.created.slice(0, 10)} {record.created.slice(11, 16)}
            </div>
          </div>
          <div className="row">
            <h4>備註</h4>
            <div>{record.note}</div>
          </div>
        </div>
      ) : (
        <Center>
          <Empty />
        </Center>
      )}

      <Divider>退款單</Divider>

      {refund ? (
        <div>
          <div className="row">
            <h4>退款單編號</h4>
            <Anchor
              type="button"
              onClick={() => {
                setSelectedRefund(refund);
                setOpenRefundDialog(true);
              }}>
              # {refund.id}
            </Anchor>
          </div>
          <div className="row">
            <h4>退款方式</h4>
            <div>{REFUND_TYPES[refund.refund_type]}</div>
          </div>
          <div className="row">
            <h4>退款單狀態</h4>
            <div>{REFUND_STATUS[refund.status]}</div>
          </div>
          <div className="row">
            <h4>經辦人</h4>
            <div>{getProfileDisplay(refund.issuer)}</div>
          </div>
          <div className="row">
            <h4>顧客名稱</h4>
            <div>{getProfileDisplay(refund.order_owner)}</div>
          </div>
          <div className="row">
            <h4>建立時間</h4>
            <div>
              {refund.created.slice(0, 10)} {refund.created.slice(11, 16)}
            </div>
          </div>
          <div className="row">
            <h4>備註</h4>
            <div>{refund.note}</div>
          </div>
        </div>
      ) : (
        <Center>
          <Empty />
        </Center>
      )}

      {openDialog && (
        <RetrunDialog
          order={order}
          returnApp={selectedRecord}
          closeDialog={() => setOpenDialog(false)}
          onUpdate={() => getRecords()}
          openRefundDialog={() => setOpenRefundDialog(true)}
        />
      )}

      {openRefundDialog && (
        <RefundDialog
          record={selectedRefund}
          returnApp={selectedRecord}
          closeDialog={() => {
            setSelectedRefund(null);
            setOpenRefundDialog(false);
          }}
          onUpdate={async () => {
            setLoading(true);
            await Promise.all([getRefunds(), getRecords()]);
            setLoading(false);
          }}
        />
      )}
    </Block>
  );
}
