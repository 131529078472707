import React, {useMemo} from 'react';
import Block from '../../Widgets/Block';
import {Divider} from 'antd';
import {getCalculation, isOrderDisabled} from '../../Utils/OrderUtil';
import {PAYMENT_STATUS, PAYMENT_STATUS_COLORS} from '../../dictionary';
import Tag from '../../Widgets/Tag';
import IconButton from '../../Widgets/IconButton';
import {Edit} from '@styled-icons/boxicons-solid/Edit';
import {UISTATE as ORDER_DIALOG_TYPE} from '../../Dialogs/OrderDialog';

const appConfig = require('../../data.json');

export default function CalculationBlock({order, setOpenOrderDialog}) {
  const {cart, disabled, calculations} = useMemo(() => {
    let cart = {};
    if (order.cart) {
      try {
        cart = JSON.parse(order.cart);
      } catch (err) {}
    }

    return {
      cart,
      disabled: isOrderDisabled(order),
      calculations: getCalculation(cart.calculations),
    };
  }, [order]);

  let {
    items_amount = 0, //商品金額（未稅）
    items_tax = 0, //商品金額（稅）
    discount = 0, //活動折抵（未稅）
    discount_tax = 0, //活動折抵（稅）
    fee = 0, //運費(未稅)
    fee_tax = 0, //運費（稅）
    bonus = 0, //紅利（未稅）
    bonus_tax = 0, //紅利（稅）
    amount = 0, //訂單總計金額(含稅)
    feedback_bonus = 0, //可獲得紅利
  } = calculations;

  return (
    <Block>
      <h2> 金額及付款狀態</h2>
      <div className="divider" />
      <div>
        <div
          className="row"
          style={{justifyContent: 'space-between', alignItems: 'center'}}>
          <h4>
            付款狀態
            <IconButton
              onClick={() => setOpenOrderDialog(ORDER_DIALOG_TYPE.PAYMENT)}
              disabled={disabled}
              hoverColor={appConfig.colors.sub}>
              <Edit />
            </IconButton>
          </h4>
          <Tag color={PAYMENT_STATUS_COLORS[order.payment_status]}>
            {PAYMENT_STATUS[order.payment_status]}
          </Tag>
        </div>
        <div className="row" style={{justifyContent: 'space-between'}}>
          <h4>商品（含稅）：</h4>
          <div>{items_amount + items_tax} 元</div>
        </div>

        <div className="row" style={{justifyContent: 'space-between'}}>
          <h4> - 紅利（含稅）：</h4>
          <div>{bonus + bonus_tax} 元</div>
        </div>

        <div className="row" style={{justifyContent: 'space-between'}}>
          <h4> - 折扣（含稅）：</h4>
          <div>{discount + discount_tax} 元</div>
        </div>

        <div className="row" style={{justifyContent: 'space-between'}}>
          <h4> + 運費（含稅）：</h4>
          <div>{fee + fee_tax} 元</div>
        </div>

        <Divider />

        <div className="row" style={{alignItems: 'flex-end'}}>
          <h4> = 總額：</h4>
          <div style={{flex: 1}} />
          <div
            style={{
              fontSize: 24,
              color: appConfig.colors.main,
              lineHeight: 'normal',
              marginRight: 5,
            }}>
            {' '}
            {amount}{' '}
          </div>
          <div>元</div>
        </div>

        <div className="row" style={{justifyContent: 'space-between'}}>
          <h4>可獲得紅利：</h4>
          {/* order.order_type !== 'credit' */}
          <div>{feedback_bonus} 元</div>
        </div>
      </div>
    </Block>
  );
}
