import React from 'react';
import styled from 'styled-components';
import {displayStatus} from '../../Utils/LogisticUtil';

const StatusTag = ({isDispatched = false, isError = false, children}) => {
  let color = '#000000';
  let backgroundColor = '#FFFFFF';

  if (isDispatched) {
    color = '#EEA23E';
    backgroundColor = '#FFF8EB';
  } else if (isError) {
    color = '#F04438';
    backgroundColor = '#FEF6F6';
  }

  return (
    <Bar color={color} backgroundColor={backgroundColor}>
      {children}
    </Bar>
  );
};

const Bar = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 8px;
  color: ${({color}) => color};
  background-color: ${({backgroundColor}) => backgroundColor};
  margin-bottom: 32px;
`;

export default StatusTag;
