const appConfig = require("../data.json");

async function fetchProductSpecByOrder(order) {
  try {
    const url = `${appConfig.endpoint.productSpecUrl}/${
      JSON.parse(order.cart).version
    }.json`;
    return await (await fetch(url)).json();
  } catch (ex) {
    const url = `${appConfig.endpoint.productSpecUrl}/latest.json`;
    return await (await fetch(url)).json();
  }
}

export default fetchProductSpecByOrder;
