import React from 'react';
import SimpleLogisticSummary from '../SimpleLogisticSummary';
import styled from 'styled-components';

function CopyModal({record, copyQuantity, setCopyQuantity}) {
  const handleDecrease = () => {
    setCopyQuantity((prev) => Math.max(1, prev - 1));
  };

  const handleIncrease = () => {
    setCopyQuantity((prev) => Math.min(5, prev + 1));
  };
  return (
    <div>
      <SimpleLogisticSummary data={[record]} />
      <Container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '10px 0',
        }}>
        <StyledButton onClick={handleDecrease} disabled={copyQuantity <= 1}>
          -
        </StyledButton>
        <Number>{copyQuantity}</Number>
        <StyledButton onClick={handleIncrease} disabled={copyQuantity >= 5}>
          +
        </StyledButton>
      </Container>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 20px;
`;

const StyledButton = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background-color: ${(props) => (props.disabled ? '#edeef1' : '#227FE9')};
  color: ${(props) => (props.disabled ? '#1C1B1F' : '#fff')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 20px;
  font-weight: 500;
  user-select: none;
`;

const Number = styled.span`
  display: inline-block;
  padding: 8px 10px;
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid #000;
  margin: 0 16px;
`;

export default CopyModal;
