import React, {useMemo} from 'react';
import Block from '../../Widgets/Block';
import {MEMBER_TYPE} from '../../dictionary';
import Anchor from '../../Widgets/Anchor';
import {useOutlet} from 'reconnect.js/build';

export default function MemberBlock({buyer, order}) {
  const [actions] = useOutlet('actions');
  const {cart, config} = useMemo(() => {
    let cart = {};
    if (order.cart) {
      try {
        cart = JSON.parse(order.cart);
      } catch (err) {}
    }

    return {
      cart,
      config: cart.config,
    };
  }, [order]);

  if (!buyer) {
    return null;
  }
  return (
    <Block>
      <h2> 客戶資訊</h2>
      <div className="divider" />
      <div>
        <div className="row">
          <h4>會員編號</h4>
          <Anchor to={`/member/?id=${buyer.id}`}>{buyer.id}</Anchor>
        </div>
        <div className="row">
          <h4>顧客名稱</h4>
          <div>{config.name}</div>
        </div>
        <div className="row">
          <h4>會員身份</h4> {/* refactor: change these requests to ONE field */}
          <div>{MEMBER_TYPE[`${buyer.user_type}`]}</div>
        </div>
        <div className="row">
          <h4>行動電話</h4>
          <div>{config.phone}</div>
        </div>

        <div className="row">
          <h4>電子信箱</h4>
          <div>{config.email}</div>
        </div>
        <div className="row">
          <h4>地址</h4>
          <div>
            {config.zip_code} {config.city} {config.district} {config.address}
          </div>
        </div>
      </div>
    </Block>
  );
}
