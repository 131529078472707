import React from 'react';
import {Table} from 'antd';
import Row from '../../Widgets/Row';
import Tag from '../../Widgets/Tag';
import {DELIVERY_TYPE} from '../../dictionary';
import styled from 'styled-components';

const SimpleLogisticSummary = ({data}) => {
  const columns = [
    {
      title: '編號',
      width: 80,
      render: (_, instance) => <span>{`#${instance.id}`}</span>,
    },
    {
      title: '訂單編號',
      width: 140,
      render: (_, instance) => <span>{instance.display_id}</span>,
    },
    {
      title: '運送方式',
      render: (_, instance) => (
        <Row>
          <div>{DELIVERY_TYPE[instance.logistics_type]}</div>
          {instance.is_delivery_private && instance.logistics_type === 'hct' ? (
            <Tag shape="circle" color="#d0543c" style={{marginLeft: 5}}>
              保
            </Tag>
          ) : null}
        </Row>
      ),
    },
    {
      title: '收件地址',
      render: (_, instance) => (
        <Row>
          {[
            instance?.receiver_city,
            instance?.receiver_district,
            instance?.receiver_address,
          ]
            .filter(Boolean)
            .join('')}
          {instance?.is_areas && (
            <span style={{color: '#EEA23E', marginLeft: '5px'}}>聯運</span>
          )}
        </Row>
      ),
    },
  ];

  return (
    <StyledTable
      columns={columns}
      dataSource={data}
      rowKey="id"
      pagination={false}
    />
  );
};

const StyledTable = styled(Table)`
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
`;

export default SimpleLogisticSummary;
