import React from 'react';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from '@react-pdf/renderer';
import moment from 'moment';
import {parseProductConfig} from './PdfCartItemTable';
import {DELIVERY_TYPE} from '../dictionary';
const appConfig = require('../data.json');

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'MiSans',
    src: '/MiSans-Normal.ttf',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  image: {
    width: 200,
    height: 150,
    objectFit: 'contain',
  },
  title: {
    backgroundColor: '#fdf0ca',
    textAlign: 'center',
    fontSize: 12,
    paddingVertical: 12,
  },
  form: {
    flexDirection: 'column',
    marginBottom: 10,
  },
  bgColorRow: {
    backgroundColor: '#f3f3f3',
    textAlign: 'center',
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  cell: {
    flex: 1,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  cellBig: {
    flex: 2,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  cellBigger: {
    flex: 5.29,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  row: {
    flexDirection: 'row',
  },
});

// Create Document Component
const WorkInnerDocument = (props) => {
  let {order, orderItems, productSpec, isPrivate = false, logistics} = props;
  let cart = JSON.parse(order.cart);
  let logistics_type =
    logistics.length > 0
      ? logistics[0].logistics_type
      : cart.config.deliveryConfig.delivery_type;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text
          style={styles.title}>{`訂單${order.display_id} - 內部工作單`}</Text>

        <View style={styles.form}>
          <Text style={styles.bgColorRow}>訂單資訊</Text>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              訂單編號:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {order.display_id}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              建立時間:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {moment(order.created).format('YYYY-MM-DD HH:MM')}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              會員名稱:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.name}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              行動電話:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {cart.config.phone}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              公司抬頭:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.invoiceConfig.company_title}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              電子信箱:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {cart.config.email}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              統一編號:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.invoiceConfig.gui_number}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              地址:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {cart.config.city} {cart.config.district}
              {cart.config.address}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              訂單備註:
            </Text>
            <Text style={[styles.cellBigger, {borderTop: 0}]}>
              {order.note}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              員工備註:
            </Text>
            <Text style={[styles.cellBigger, {borderTop: 0}]}>
              {order.staff_note}
            </Text>
          </View>
        </View>

        <View style={styles.form}>
          <Text style={styles.bgColorRow}>物流單資訊</Text>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              物流單編號:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {logistics?.[0]?.id}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              運送方式:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {DELIVERY_TYPE[logistics_type]}
            </Text>
          </View>
          {logistics_type !== 'self_pick' && (
            <>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  保密代寄:
                </Text>
                <Text style={[styles.cellBigger, {borderTop: 0}]}>
                  {logistics?.[0]?.is_delivery_private ? '是' : '否'}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  收件人姓名:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
                  {logistics?.[0]?.receiver_name}
                </Text>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  寄件人姓名:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {logistics?.[0]?.sender_name}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  收件人地址:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
                  {logistics?.[0]?.receiver_city}
                  {logistics?.[0]?.receiver_district}
                  {logistics?.[0]?.receiver_address}
                </Text>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  寄件人地址:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {logistics?.[0]?.sender_city}
                  {logistics?.[0]?.sender_district}
                  {logistics?.[0]?.sender_address}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  收件人手機:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
                  {logistics?.[0]?.receiver_phone}
                </Text>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  寄件人手機:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {logistics?.[0]?.sender_phone}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  收件人市話:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
                  {logistics?.[0]?.receiver_tel}
                </Text>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  寄件人市話:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {logistics?.[0]?.sender_tel}
                </Text>
              </View>
            </>
          )}

          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              {logistics_type !== 'self_pick'
                ? '預計出貨時間:'
                : '預計自取時間:'}
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {logistics?.[0]?.shipping_time
                ? logistics?.[0]?.shipping_time.slice(0, 10)
                : '---'}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              {logistics_type !== 'self_pick'
                ? '指定收貨時間:'
                : '指定自取時間:'}
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {logistics?.[0]?.receipt_time
                ? logistics?.[0]?.receipt_time.slice(0, 10) +
                  ' ' +
                  logistics?.[0]?.receipt_time.slice(11, 16)
                : '未指定'}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              備註:
            </Text>
            <Text style={[styles.cellBigger, {borderTop: 0}]}>
              {logistics?.[0]?.logistics_note}
            </Text>
          </View>
        </View>

        <View style={styles.form}>
          <View style={[styles.row, {padding: 0, backgroundColor: '#f3f3f3'}]}>
            <Text style={[styles.cell, {borderRight: 0, textAlign: 'center'}]}>
              稿件編號
            </Text>
            <Text
              style={[styles.cellBig, {borderRight: 0, textAlign: 'center'}]}>
              商品名稱
            </Text>
            <Text
              style={[styles.cellBig, {borderRight: 0, textAlign: 'center'}]}>
              規格
            </Text>
            {isPrivate ? (
              <View
                style={{
                  borderRightColor: '#b9b9b9',
                  borderRightWidth: 1,
                }}></View>
            ) : (
              <Text style={styles.cell}>金額小計(含稅)</Text>
            )}
          </View>

          {orderItems.map((orderItem, idx) => (
            <React.Fragment>
              <View style={styles.row}>
                <Text
                  style={[
                    styles.cell,
                    {borderTop: 0, borderRight: 0, textAlign: 'center'},
                  ]}>
                  #{orderItem.id}
                </Text>
                <View
                  style={[
                    styles.cellBig,
                    {borderTop: 0, borderRight: 0, alignItems: 'flex-start'},
                  ]}>
                  <Text>
                    {order.custom_note || cart.items[orderItem.item_index].name}
                  </Text>
                </View>
                <View
                  style={[
                    styles.cellBig,
                    {borderTop: 0, borderRight: 0, flexDirection: 'column'},
                  ]}>
                  {(() => {
                    const itemInCart = {...cart.items[orderItem.item_index]};
                    if (isPrivate) {
                      delete itemInCart.config.amount_select;
                      delete itemInCart.config.logistic_select;
                    }
                    const parsedConfigs = parseProductConfig({
                      itemInCart,
                      productSpec,
                      order,
                    });
                    return parsedConfigs.map((config, idx) => (
                      <Text key={idx} style={{paddingVertical: 0}}>
                        {config}
                      </Text>
                    ));
                  })()}
                  {order.order_type === 'custom' ? (
                    <Text style={{fontSize: 10}}>
                      自定規格：{cart.items[orderItem.item_index].custom_note}
                    </Text>
                  ) : null}
                </View>
                {!isPrivate ? (
                  <Text style={[styles.cell, {borderTop: 0}]}>
                    ${cart.items[orderItem.item_index].price}
                  </Text>
                ) : (
                  <View
                    style={{
                      borderRightColor: '#b9b9b9',
                      borderRightWidth: 1,
                    }}></View>
                )}
              </View>
              {orderItem.thumbnail_url && (
                <View style={styles.row}>
                  <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                    縮圖:
                  </Text>
                  <View style={[styles.cellBigger, {borderTop: 0}]}>
                    {/* use S3 host avoid cloudfront CORS header missing */}
                    {/* <Image src={orderItem.thumbnail_url} style={styles.image} /> */}
                    <Image
                      src={
                        appConfig.endpoint.productSpecUrl.replace(
                          '/product',
                          '',
                        ) +
                        orderItem.thumbnail_url.slice(
                          orderItem.thumbnail_url.lastIndexOf('/'),
                        ) +
                        '?noCache=true' // to fix browser cache which makes CORS error
                      }
                      style={styles.image}
                    />
                  </View>
                </View>
              )}
            </React.Fragment>
          ))}
          {!isPrivate ? (
            <View>
              <View style={styles.row}>
                <Text
                  style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
                  商品小計(含稅)
                </Text>
                <Text style={[styles.cell, {borderTop: 0}]}>
                  $
                  {cart.calculations.items_amount + cart.calculations.items_tax}
                </Text>
              </View>
              <View style={styles.row}>
                <Text
                  style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
                  運費(含稅)
                </Text>
                <Text style={[styles.cell, {borderTop: 0}]}>
                  ${cart.calculations.fee + cart.calculations.fee_tax}
                </Text>
              </View>
              <View style={styles.row}>
                <Text
                  style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
                  紅利(含稅)
                </Text>
                <Text style={[styles.cell, {borderTop: 0}]}>
                  ${cart.calculations.bonus + cart.calculations.bonus_tax}
                </Text>
              </View>
              <View style={styles.row}>
                <Text
                  style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
                  折扣(含稅)
                </Text>
                <Text style={[styles.cell, {borderTop: 0}]}>
                  ${cart.calculations.discount + cart.calculations.discount_tax}
                </Text>
              </View>
              <View style={styles.row}>
                <Text
                  style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
                  總計(含稅)
                </Text>
                <Text style={[styles.cell, {borderTop: 0}]}>
                  ${cart.calculations.amount}
                </Text>
              </View>
            </View>
          ) : null}
        </View>
      </Page>
    </Document>
  );
};
const WorkInnerFormPdfDownload = (props) => {
  const cart = JSON.parse(props.order.cart);
  return (
    <PDFDownloadLink
      style={props.style}
      document={<WorkInnerDocument {...props} />}
      fileName={`${props.order.id}-${decodeURI(cart.config.name)}-工單.pdf`}>
      {({blob, url, loading, error}) => (loading ? '下載中...' : `匯出工單`)}
    </PDFDownloadLink>
  );
};

export {WorkInnerFormPdfDownload, WorkInnerDocument};
