import React, {useMemo} from 'react';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import {navigate} from 'gatsby';

export default function PeriodBlock(props) {
  const {order} = props;

  const {Status, Message, Result = {}} = useMemo(() => {
    try {
      let obj = JSON.parse(order?.payment_transaction_detail);
      return {...obj};
    } catch (err) {
      return {};
    }
  }, [order]);

  return (
    <Block>
      <div style={{display: 'flex'}}>
        <h2>委託單資訊</h2>
        <div style={{flex: 1}} />
        <Button onClick={() => navigate(`/period/?id=${order?.period_order}`)}>
          查看
        </Button>
      </div>
      <div className="divider" />
      {order ? (
        <div>
          <div className="row">
            <h4>委託單成立狀態</h4>
            <p>{Status === 'SUCCESS' ? '成功' : '錯誤 ' + Status}</p>
          </div>
          <div className="row">
            <h4>藍新回傳訊息</h4>
            <p>{Message}</p>
          </div>
          <div className="row">
            <h4>期數</h4>
            <p>
              {(Result.AlreadyTimes || 1) +
                '/' +
                (Result.TotalTimes || Result.AuthTimes)}
              {/* // first tranx is different */}
            </p>
          </div>
          <div className="row">
            <h4>授權金額</h4>
            <p>{Result.AuthAmt || Result.PeriodAmt}</p>
          </div>
          <div className="row">
            <h4>扣款日</h4>
            <p>
              {Result.AuthDate
                ? Result.AuthDate.slice(0, 10)
                : Result?.DateArray
                ? Result.DateArray.split(',')[0]
                : ''}
              {/* // first tranx is different */}
            </p>
          </div>
        </div>
      ) : (
        <div> 載入中... </div>
      )}
    </Block>
  );
}
