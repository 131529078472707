import React from 'react';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from '@react-pdf/renderer';
import {getQuantity, parseProductConfig} from './PdfCartItemTable';
import appConfig from '../data.json';

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'MiSans',
    src: '/MiSans-Normal.ttf',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  image: {
    width: 200,
    height: 150,
    objectFit: 'contain',
  },
  title: {
    backgroundColor: '#d8ead3',
    textAlign: 'center',
    fontSize: 12,
    paddingVertical: 12,
  },
  text: {
    fontWeight: 500,
    fontSize: 10,
    paddingVertical: 12,
  },
  textBig: {
    fontSize: 14,
  },
  form: {
    marginBottom: 10,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#b9b9b9',
  },
  firstRow: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f3f3f3',
    borderBottomColor: '#b9b9b9',
    borderBottomWidth: 1,
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: '#b9b9b9',
    borderBottomWidth: 1,
  },
  firstRowCell: {
    textAlign: 'center',
    fontSize: 10,
    flex: 1,
    paddingHorizontal: 3,
    paddingVertical: 12,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  firstRowCellBig: {
    textAlign: 'center',
    fontSize: 10,
    flex: 2,
    paddingHorizontal: 3,
    paddingVertical: 12,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  cell: {
    fontSize: 10,
    flex: 1,
    paddingHorizontal: 3,
    paddingVertical: 12,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  cellBig: {
    flexDirection: 'column',
    fontSize: 10,
    flex: 2,
    paddingHorizontal: 3,
    paddingVertical: 12,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  cellBigger: {
    flexDirection: 'column',
    fontSize: 10,
    flex: 5.19,
    paddingHorizontal: 3,
    paddingVertical: 12,
    borderRightColor: '#b9b9b9',
    borderRightWidth: 1,
  },
  cellMini: {
    fontSize: 10,
    flex: 1,
    padding: 1,
    borderBottomColor: '#b9b9b9',
    borderBottomWidth: 1,
  },
});

// Create Document Component
const WorkDocument = (props) => {
  let {order, orderItems, productSpec} = props;
  let cart = JSON.parse(order.cart);

  return (
    <Document>
      {orderItems.map((orderItem, idx) => {
        return (
          <Page size="A4" style={styles.page} key={idx}>
            <View>
              <Text style={styles.title}>
                {`廠商工作單-稿件編號 #${orderItem.id}`}
              </Text>
              <View style={[styles.form, {borderBottom: 0}]}>
                <View style={styles.firstRow}>
                  <Text style={styles.text}>工單資訊</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.cell}>訂單編號：</Text>
                  <Text style={styles.cellBig}>{order.display_id}</Text>
                  <Text style={styles.cell}>訂單日期：</Text>
                  <Text style={[styles.cellBig, {borderRight: 0}]}>
                    {order.created.split('T')[0]}
                  </Text>
                </View>

                <View style={styles.row}>
                  <Text style={styles.cell}>審稿人員：</Text>
                  <Text style={[styles.cellBigger, {borderRight: 0}]}>
                    {orderItem.assignee ? orderItem.assignee.name : '無指派'}
                  </Text>
                </View>

                <View style={styles.row}>
                  <Text style={styles.cell}>訂單備註：</Text>
                  <Text style={[styles.cellBigger, {borderRight: 0}]}>
                    {order.note}
                  </Text>
                </View>

                <View style={styles.row}>
                  <Text style={styles.cell}>員工備註：</Text>
                  <Text style={[styles.cellBigger, {borderRight: 0}]}>
                    {order.staff_note}
                  </Text>
                </View>
              </View>

              <View style={[styles.form, {borderBottom: 0}]}>
                <View style={{...styles.firstRow, backgroundColor: '#f3f3f3'}}>
                  <Text style={[styles.cell, {textAlign: 'center'}]}>
                    稿件編號
                  </Text>
                  <Text style={[styles.cellBig, {textAlign: 'center'}]}>
                    商品名稱
                  </Text>
                  <Text style={[styles.cellBig, {textAlign: 'center'}]}>
                    規格
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      {borderRightWidth: 0, textAlign: 'center'},
                    ]}>
                    廠商
                  </Text>
                </View>

                <View>
                  <View style={styles.row}>
                    <Text style={styles.cell}>＃{orderItem.id}</Text>
                    <Text style={styles.cellBig}>
                      {order.custom_note ||
                        cart.items[orderItem.item_index].name}
                    </Text>
                    <View style={styles.cellBig}>
                      {(() => {
                        const itemInCart = {
                          ...cart.items[orderItem.item_index],
                        };
                        delete itemInCart.config.amount_select;
                        delete itemInCart.config.logistic_select;
                        const parsedConfigs = parseProductConfig({
                          itemInCart,
                          productSpec,
                          order,
                        });
                        return parsedConfigs.map((config, idx) => (
                          <Text key={idx} style={{fontSize: 10}}>
                            {config}
                          </Text>
                        ));
                      })()}
                      {order.order_type === 'custom' ? (
                        <Text style={{fontSize: 10}}>
                          自定規格：
                          {cart.items[orderItem.item_index].custom_note}
                        </Text>
                      ) : null}
                      <Text style={{fontSize: 10}}>
                        訂購數量(個)：
                        {getQuantity(cart.items[orderItem.item_index])}
                      </Text>
                    </View>

                    <Text style={[styles.cell, {borderRight: 0}]}>
                      {orderItem.supplier ? orderItem.supplier.name : '---'}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.cell}>稿件名稱:</Text>
                    <Text style={[styles.cellBigger, {borderRight: 0}]}>
                      {orderItem.latest_attachment
                        ? orderItem.latest_attachment.file
                        : '---'}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.cell}>傳檔備註:</Text>
                    <Text style={[styles.cellBigger, {borderRight: 0}]}>
                      {orderItem.latest_attachment
                        ? orderItem.latest_attachment.note
                        : '---'}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.form}>
                <View style={styles.firstRow}>
                  <Text style={styles.text}>審稿及印製資訊</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.cell}>審稿人員備註:</Text>
                  <Text style={[styles.cellBigger, {borderRight: 0}]}>
                    {orderItem.note}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.cell}>審稿附圖:</Text>
                  <View style={[styles.cellBigger, {borderRight: 0}]}>
                    {orderItem.thumbnail_url && (
                      // use S3 host avoid cloudfront CORS header missing
                      <Image
                        src={
                          appConfig.endpoint.productSpecUrl.replace(
                            '/product',
                            '',
                          ) +
                          orderItem.thumbnail_url.slice(
                            orderItem.thumbnail_url.lastIndexOf('/'),
                          ) +
                          '?noCache=true' // to fix browser cache which makes CORS error
                        }
                        style={styles.image}
                      />
                      // <Image src={objectUrl} style={styles.image} />
                    )}
                  </View>
                </View>
                <View style={styles.row}>
                  <Text style={styles.cell}>工單完成日期:</Text>
                  <Text style={styles.cellBig}></Text>
                  <Text style={styles.cell}>預計出貨日期:</Text>
                  <Text style={[styles.cellBig, {borderRight: 0}]}></Text>
                </View>
                <View style={[styles.row]}>
                  <Text style={styles.cell}>印務簽名:</Text>
                  <Text style={[styles.cellBigger, {borderRight: 0}]}></Text>
                </View>
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};
const WorkFormPdfDownload = (props) => {
  const cart = JSON.parse(props.order.cart);
  return (
    <PDFDownloadLink
      style={props.style}
      document={<WorkDocument {...props} />}
      fileName={`${props.order.id}-${decodeURI(
        cart.config.name,
      )}-廠商工作單.pdf`}>
      {({blob, url, loading, error}) =>
        loading ? '下載中...' : `匯出廠商工作單`
      }
    </PDFDownloadLink>
  );
};

export {WorkFormPdfDownload, WorkDocument};
