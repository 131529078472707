import React from 'react';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from '@react-pdf/renderer';
import moment from 'moment';
import {parseProductConfig} from './PdfCartItemTable';
import {EXTRA_ORDER_TYPE, PAYMENT_STATUS} from '../dictionary';
import {getPaymentType} from '../Utils/OrderUtil';
const appConfig = require('../data.json');

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'MiSans',
    src: '/MiSans-Normal.ttf',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 40,
  },
  title: {
    textAlign: 'center',
    marginBottom: 10,
  },
  form: {
    flexDirection: 'column',
    marginBottom: 10,
  },
  bgColorRow: {
    backgroundColor: '#f3f3f3',
    textAlign: 'center',
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  cell: {
    flex: 1,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  cellBig: {
    flex: 2,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  cellBigger: {
    flex: 5.29,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  row: {
    flexDirection: 'row',
  },
  image: {
    maxWidth: 200,
    height: 150,
    objectFit: 'contain',
  },
  invoice: {
    width: 200,
  },
});

// Create Document Component
const SalesDocument = (props) => {
  let {order, orderItems, productSpec} = props;
  let {information} = appConfig;
  let cart = JSON.parse(order.cart);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>
          {information.name.replace(/\s/g, '')}-銷貨單
        </Text>

        <View style={styles.form}>
          <Text style={styles.bgColorRow}>訂單資訊</Text>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              訂單編號:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {order.display_id}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              建立時間:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {moment(order.created).format('YYYY-MM-DD HH:MM')}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              客戶名稱:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.name}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              行動電話:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {cart.config.phone}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              公司抬頭:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.invoiceConfig.company_title}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              電子信箱:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {cart.config.email}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              統一編號:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.invoiceConfig.gui_number}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              地址:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {cart.config.city} {cart.config.district}
              {cart.config.address}
            </Text>
          </View>
        </View>

        <View style={styles.form}>
          <View style={[styles.row, {padding: 0, backgroundColor: '#f3f3f3'}]}>
            <Text style={[styles.cell, {borderRight: 0, textAlign: 'center'}]}>
              品項
            </Text>
            <Text
              style={[styles.cellBig, {borderRight: 0, textAlign: 'center'}]}>
              商品名稱
            </Text>
            <Text
              style={[styles.cellBig, {borderRight: 0, textAlign: 'center'}]}>
              規格
            </Text>
            <Text style={styles.cell}>金額小計(含稅)</Text>
          </View>

          {orderItems.map((orderItem, idx) => (
            <React.Fragment key={idx}>
              <View style={styles.row}>
                <Text
                  style={[
                    styles.cell,
                    {borderTop: 0, borderRight: 0, textAlign: 'center'},
                  ]}>
                  {idx + 1}
                </Text>
                <View
                  style={[
                    styles.cellBig,
                    {borderTop: 0, borderRight: 0, alignItems: 'flex-start'},
                  ]}>
                  <Text>
                    {order.custom_note || cart.items[orderItem.item_index].name}
                  </Text>
                </View>
                <View
                  style={[
                    styles.cellBig,
                    {borderTop: 0, borderRight: 0, flexDirection: 'column'},
                  ]}>
                  {(() => {
                    const parsedConfigs = parseProductConfig({
                      itemInCart: cart.items[orderItem.item_index],
                      productSpec,
                      order,
                    });
                    return parsedConfigs.map((config, idx) => (
                      <Text key={idx} style={{paddingVertical: 0}}>
                        {config}
                      </Text>
                    ));
                  })()}
                  {order.order_type === 'custom' ? (
                    <Text style={{fontSize: 10}}>
                      自定規格：{cart.items[orderItem.item_index].custom_note}
                    </Text>
                  ) : null}
                </View>
                <Text style={[styles.cell, {borderTop: 0}]}>
                  ${cart.items[orderItem.item_index].price}
                </Text>
              </View>
              {orderItem.thumbnail_url && (
                <View style={styles.row}>
                  <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                    縮圖:
                  </Text>
                  <View style={[styles.cellBigger, {borderTop: 0}]}>
                    {/* use S3 host avoid cloudfront CORS header missing */}
                    <Image
                      src={
                        appConfig.endpoint.productSpecUrl.replace(
                          '/product',
                          '',
                        ) +
                        orderItem.thumbnail_url.slice(
                          orderItem.thumbnail_url.lastIndexOf('/'),
                        ) +
                        '?noCache=true' // to fix browser cache which makes CORS error
                      }
                      style={styles.image}
                    />
                    {/* <Image src={orderItem.thumbnail_url} style={styles.image} /> */}
                  </View>
                </View>
              )}
            </React.Fragment>
          ))}

          <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              商品小計(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.items_amount + cart.calculations.items_tax}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              運費(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.fee + cart.calculations.fee_tax}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              紅利(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.bonus + cart.calculations.bonus_tax}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              折扣(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.discount + cart.calculations.discount_tax}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              總計(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.amount}
            </Text>
          </View>
        </View>

        {order.extra_order.length > 0 ? (
          <View style={styles.form}>
            <View
              style={[styles.row, {padding: 0, backgroundColor: '#f3f3f3'}]}>
              <Text style={[styles.cell, {borderRight: 0}]}>補收款單號</Text>
              <Text style={[styles.cell, {borderRight: 0}]}>補收類別</Text>
              <Text style={[styles.cell, {borderRight: 0}]}>補收原因</Text>
              <Text style={[styles.cell, {borderRight: 0}]}>
                補收金額(含稅)
              </Text>
              <Text style={[styles.cell, {borderRight: 0}]}>付款狀態</Text>

              <Text style={styles.cell}> 付款方式</Text>
            </View>

            {order.extra_order.map((extra, idx) => (
              <View style={styles.row} key={idx}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  {extra.display_id}
                </Text>
                <View style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  <Text>{EXTRA_ORDER_TYPE[extra.extra_type]}</Text>
                </View>
                <View style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  <Text> {extra.description} </Text>
                </View>
                <View style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  <Text> {extra.amount} </Text>
                </View>
                <View style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  <Text> {PAYMENT_STATUS[extra.payment_status]} </Text>
                </View>
                <Text style={[styles.cell, {borderTop: 0}]}>
                  {getPaymentType(extra)}
                </Text>
              </View>
            ))}
          </View>
        ) : null}

        <View style={styles.form}>
          <Text style={styles.bgColorRow}>銷貨單確認</Text>
          <View style={styles.row}>
            <Text
              style={[styles.cell, {borderTop: 0, borderRight: 0, flex: 1}]}>
              客戶簽收:
            </Text>
            <View style={{flex: 1}}>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  公司抬頭:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {information.fullname}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  統一編號:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {information.gui_number}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  聯絡電話:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {information.tel}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  電子信箱:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {information.email}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              訂購確認日期:
            </Text>
            <Text style={[styles.cellBigger, {borderTop: 0}]}></Text>
          </View>
          <View style={styles.row}>
            <Image
              src={`/images/${appConfig.information.invoice_seal_images}`}
              style={styles.invoice}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};
const SalesOrderPdfDownload = (props) => {
  const cart = JSON.parse(props.order.cart);
  return (
    <PDFDownloadLink
      style={props.style}
      document={<SalesDocument {...props} />}
      fileName={`${props.order.id}-${decodeURI(cart.config.name)}-銷貨單.pdf`}>
      {({blob, url, loading, error}) => (loading ? '下載中...' : '匯出銷貨單')}
    </PDFDownloadLink>
  );
};

export {SalesOrderPdfDownload, SalesDocument};
