import React, {useEffect, useState, useMemo} from 'react';
import Block from '../../Widgets/Block';

export default function CreditOrderDataBlock(props) {
  const {order} = props;

  return (
    <Block>
      <h2> 點數資訊</h2>
      <div className="divider" />
      <div>
        <div className="row">
          <h4>購買點數</h4>
          <div>{order.amount}</div>
        </div>
      </div>
    </Block>
  );
}
