import React, {useState, useEffect, useCallback} from 'react';
import {Modal, Button, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import EditModal from './Edit';
import AddModal from './Add';
import CopyModal from './Copy';
import DispatchModal from './Dispatch';
import CancelModal from './Cancel';
import styled from 'styled-components';
import {
  handleAddSubmit,
  handleEditSubmit,
  handleCopySubmit,
  handleDispatchSubmit,
  handleDispatchBatchSubmit,
  handleCancelSubmit,
} from './submitHandlers';

const LogisticModal = ({
  visible,
  type,
  record,
  params,
  reset,
  orderFromDetailPage = null,
  onClose,
  onUpdate,
}) => {
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [orderID, setOrderID] = useState('');
  const [order, setOrder] = useState(orderFromDetailPage || null);
  const [logistic, setLogistic] = useState(null);
  const [copyQuantity, setCopyQuantity] = useState(1);

  const initializeState = () => {
    setOrderID('');
    setOrder(null);
    setLogistic(null);
    setCopyQuantity(1);
    onClose();
    reset();
  };

  const handleModalOnSubmit = () => {
    initializeState();
    onUpdate();
  };

  const renderContent = () => {
    switch (type) {
      case 'add':
        return (
          <AddModal
            order={order}
            orderID={orderID}
            setOrderID={setOrderID}
            params={params}
            logistic={logistic}
            setLogistic={setLogistic}
          />
        );
      case 'edit':
        return (
          <EditModal
            record={record}
            logistic={logistic}
            setLogistic={setLogistic}
            params={params}
          />
        );
      case 'copy':
        return (
          <CopyModal
            record={record}
            copyQuantity={copyQuantity}
            setCopyQuantity={setCopyQuantity}
          />
        );
      case 'dispatch':
      case 'dispatch_batch':
        return <DispatchModal record={record} />;
      case 'cancel':
        return <CancelModal record={record} onClose={onClose} />;
      default:
        return null;
    }
  };

  const getConfirmButtonText = () => {
    switch (type) {
      case 'add':
        return '新增';
      case 'edit':
        return '確認';
      case 'copy':
        return '複製';
      case 'dispatch':
        return '拋單';
      case 'cancel':
        return '作廢';
      default:
        return '確認';
    }
  };

  const handleNextStep = useCallback(
    async (orderIdFromParams) => {
      setLoading(true);
      try {
        const id =
          typeof orderIdFromParams === 'string' ? orderIdFromParams : orderID;
        if (!id) {
          message.error('請輸入訂單編號');
          setLoading(false);
          return;
        }
        const {results} = await actions.getOrders({search: id});
        if (results.length === 1) {
          setOrder(results[0]);
        } else {
          message.error('找不到此筆訂單');
        }
      } catch (error) {
        message.error('訂單編號錯誤');
        console.error(error);
      }
      setLoading(false);
    },
    [actions, orderID, setLoading, setOrder],
  );

  const handleSubmit = async () => {
    setLoading(true);
    try {
      switch (type) {
        case 'add':
          await handleAddSubmit(
            logistic,
            actions,
            order,
            setLoading,
            handleModalOnSubmit,
          );
          break;
        case 'edit':
          await handleEditSubmit(
            logistic,
            actions,
            setLoading,
            handleModalOnSubmit,
          );
          break;
        case 'copy':
          await handleCopySubmit(
            record?.id,
            copyQuantity,
            actions,
            setLoading,
            handleModalOnSubmit,
          );
          break;
        case 'dispatch':
          await handleDispatchSubmit(
            record,
            actions,
            setLoading,
            handleModalOnSubmit,
          );
          break;
        case 'dispatch_batch':
          await handleDispatchBatchSubmit(
            record,
            actions,
            setLoading,
            handleModalOnSubmit,
          );
          break;
        case 'cancel':
          await handleCancelSubmit(
            record,
            actions,
            setLoading,
            handleModalOnSubmit,
          );
          break;
        default:
          handleModalOnSubmit();
          break;
      }
    } catch (error) {
      message.error('異常錯誤，請聯繫客服人員');
      console.error(error);
    }
    setLoading(false);
  };

  const ModalHeader = ({logistic}) => {
    const getModalTitle = (logistic) => {
      switch (type) {
        case 'add':
          return '新增物流單';
        case 'edit':
          return `物流單 #${logistic?.id}`;
        case 'copy':
          return '複製物流單';
        case 'dispatch':
        case 'dispatch_batch':
          return '拋送物流單';
        case 'cancel':
          return '作廢物流單';
        default:
          return '';
      }
    };
    return (
      <div>
        <h3>{getModalTitle(logistic)}</h3>
      </div>
    );
  };

  const ModalFooter = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}>
      {type.includes('dispatch') && (
        <div
          key="hint"
          style={{
            backgroundColor: '#DBEFFE',
            color: '#227fe9',
            borderRadius: '100px',
            padding: '8px 12px',
            fontSize: '14px',
            fontWeight: '400',
            marginRight: 'auto',
          }}>
          <span
            style={{
              display: 'inline-block',
              marginRight: '4px',
              fontWeight: '700',
            }}>
            {record.length || 1}
          </span>
          筆物流單
        </div>
      )}
      {(type !== 'add' || (type === 'add' && order)) && (
        <>
          <StyledButton key="cancel" onClick={initializeState}>
            取消
          </StyledButton>
          <StyledButton key="submit" type="primary" onClick={handleSubmit}>
            {getConfirmButtonText()}
          </StyledButton>
        </>
      )}
      {!order && type === 'add' && (
        <StyledButton
          key="next"
          type="primary"
          loading={loading}
          onClick={handleNextStep}
          onMouseDown={(e) => e.preventDefault()}>
          下一步
        </StyledButton>
      )}
    </div>
  );

  const getModalWidth = () => {
    // 編輯、新增物流單
    if (type === 'edit' || (type === 'add' && order)) {
      return 900;
    } else if (type === 'copy' || type === 'dispatch' || type === 'cancel') {
      return 720;
      // 新增物流單 - 輸入訂單編號
    } else if (type === 'add' && !order) {
      return 480;
    }
    return 900;
  };

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      if (params?.type === 'add') {
        handleNextStep(params?.order_id);
      }
    }
  }, [params, handleNextStep]);

  return (
    <StyledModal
      visible={visible}
      destroyOnClose={true}
      onCancel={initializeState}
      title={<ModalHeader logistic={logistic} />}
      footer={<ModalFooter />}
      width={getModalWidth()}>
      {renderContent()}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 20px 24px;
    h3 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
  .ant-modal-close {
    top: 4px;
  }
  .ant-modal-footer {
    padding: 20px 24px;
  }
  /* https://github.com/vueComponent/ant-design-vue/issues/7749 */
  div[aria-hidden='true'] {
    display: none !important;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    width: 80px;
    height: 48px;
    border-radius: 10px;
    color: #227fe9;
    border: 2px solid #227fe9;
    margin-right: 16px;
    &.ant-btn-primary {
      background-color: #227fe9;
      color: white;
      margin-right: 0;
    }
  }
`;

export default LogisticModal;
